import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = () => {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
      }}
    >
      <div style={{ width: 60, alignSelf: "center" }}>
        <CircularProgress color="secondary"/>
      </div>
    </div>
  );
};

export default Loading;
