import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

const useToken = () =>
  useSelector((state: RootState) => {
    const authReducer = state.authReducer;

    return authReducer?.session?.accessToken ?? "";
  });

export default useToken;
