import jsonFetch from "./jsonFetch";
import environment from "environment";
import {
  SolicitudPagosRequest,
  PagosResponse,
  InfoParaLiquidarRequest,
  InfoParaLiquidarResponse,
  ImpuestoSelladoProvincialRequest,
  ImpuestoSelladoProvincialResponse,
  AnularLiquidacionRequest,
  Banco,
  InformarTipoContribuyenteRequest,
  InformarPagosRequest,
  SolicitarSeguroRequest,
} from "./types/originacion/services/liquidacionTypes";
import { IListItem } from "./types/originacion/commonTypes";
import convertToQueryString from "./convertToQueryString";

const solicitudesLiquidacion = (token: string) => {
  return {
    getPagos: (request: SolicitudPagosRequest): Promise<PagosResponse> => {
      return jsonFetch<any>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.solicitudId}/liquidacion/pagos`,
      });
    },
    getInfoParaLiquidar: (
      request: InfoParaLiquidarRequest
    ): Promise<InfoParaLiquidarResponse> => {
      return jsonFetch<any>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.id}/liquidacion/infoParaLiquidar`,
      });
    },
    getImpuestoSellado: (
      request: ImpuestoSelladoProvincialRequest
    ): Promise<ImpuestoSelladoProvincialResponse> => {
      const params = request ? `?${convertToQueryString(request)}` : "";
      return jsonFetch<any>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/liquidacion/impuestoSelladoProvincial${params}`,
      });
    },
    anularLiquidacion: (request: AnularLiquidacionRequest): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.solicitudId}/liquidacion/anular`,
        method: "POST",
        jsonBody: request,
      });
    },
    getBancos: (): Promise<IListItem[]> => {
      return jsonFetch<Banco[]>({
        token,
        endpoint: `${environment.originacionUrl}/bancos`,
        method: "GET",
      }).then((bancosList: Banco[]): IListItem[] => {
        return bancosList.map(
          (banco: Banco) =>
            ({ value: banco.id, label: banco.nombre } as IListItem)
        );
      });
    },
    informarTipoContribuyente: (
      request: InformarTipoContribuyenteRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.id}/liquidacion/informarTipoContribuyente`,
        method: "POST",
        jsonBody: request,
      });
    },
    confirmarPagos: (request: InformarPagosRequest): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.id}/liquidacion/InformarPagos`,
        method: "POST",
        jsonBody: request,
      });
    },
    solicitarSeguro: (request: SolicitarSeguroRequest): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.id}/liquidacion/solicitarEmisionSeguro`,
        method: "POST",
        jsonBody: request,
      });
    },
  };
};

export { solicitudesLiquidacion };
