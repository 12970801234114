const environment = {
  name: process.env.REACT_APP_ENV,
  version: process.env.REACT_APP_VERSION,
  versionDescription:
    `v${process.env.REACT_APP_VERSION}` +
    (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== "production"
      ? ` (${process.env.REACT_APP_ENV.substring(0, 3)})`
      : ""),

  originacionUrl: process.env.REACT_APP_API_ORIGINACION_URL,
  creditRiskUrl: process.env.REACT_APP_API_CREDITRISK_URL,
  euroUrl: process.env.REACT_APP_API_EURO_URL,
  segurosUrl: process.env.REACT_APP_API_SEGUROS_URL,
  presignerUrl: process.env.REACT_APP_PRESIGNER_URL,
  vehiclesUrl: process.env.REACT_APP_API_VEHICLES_URL,
  amplitudeAPIkey: process.env.REACT_APP_AMPLITUDE_API_KEY,

  launchDarkly: {
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDKKEY,
  },

  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  },
  disablePermisology: process.env.REACT_APP_DISABLE_PERMISOLOGY === 'true'
};

export default environment;
