import { Middleware } from "redux";
import { logEventAnalytics } from "../libs/amplitudeHelper";

const analyticsMiddleware: Middleware = (api) => (next) => (action) => {
  if (!(action.meta && action.meta.doNotSendToAnalytics)) {
    try {
      logEventAnalytics(
        action.type,
        action.payload
          ? {
              ...action.payload,
            }
          : null
      );
    } catch (error) {
      console.error("Analytics error", error);
    }
  }

  return next(action);
};

export default analyticsMiddleware;
