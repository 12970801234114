import jsonFetch from "./jsonFetch";
import environment from "environment";
import {
  SolicitudInicioCotizacionRequest,
  SolicitudInicioCotizacionResponse,
  SolicitudResultadoCotizacionRequest,
  GetCotizacionResponse,
  SolicitudCotizacionRequest,
} from "./types/originacion/services/segurosTypes";

const solicitudesSeguros = (token: string) => {
  return {
    getIniciar: (
      request: SolicitudInicioCotizacionRequest
    ): Promise<SolicitudInicioCotizacionResponse> => {
      return jsonFetch<any>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.id}/iniciocotizacion`,
      });
    },
    getCotizaciones: (
      request: SolicitudResultadoCotizacionRequest
    ): Promise<GetCotizacionResponse> => {
      return jsonFetch<any>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.id}/resultadocotizacion?CotizacionId=${request.cotizacionId}`,
      });
    },
    postCotizacion: (request: SolicitudCotizacionRequest): Promise<any> => {
      return jsonFetch<any, SolicitudCotizacionRequest>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${request.id}/cotizacion`,
        method: "POST",
        jsonBody: request,
      });
    },
  };
};

export { solicitudesSeguros };
