import { Reducer } from "redux";

export interface ApplicationState {}
const initialState: ApplicationState = {};

const reducer: Reducer<ApplicationState> = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export { reducer as appReducer };
