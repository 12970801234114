import amplitude from "amplitude-js";
import environment from "../environment";

// docs: https://developers.amplitude.com/

let disableAnalytics = environment.name === "localhost";
//disableAnalytics = false;

export const initAnalytics = (appVersion: string) => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude
    .getInstance()
    .init(environment.amplitudeAPIkey ? environment.amplitudeAPIkey : "");
  amplitude.getInstance().setVersionName(appVersion);
};

export const logOutUserAnalytics = () => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().setUserId(null);
  //amplitude.getInstance().regenerateDeviceId();
};

export const logInUserAnalytics = (
  email: string,
  name: string,
  userId: string,
  euroUserId: string,
  appVersion: string
) => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().setUserId(userId);
  amplitude.getInstance().setUserProperties({
    email,
    nombre: name,
    userId,
    euroUserId,
    appVersion,
  });
};

export const logEventAnalytics = (eventName: string, eventData: any) => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().logEvent(eventName, eventData);
};
