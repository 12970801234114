import { createActions, handleActions } from "redux-actions";
import { Reducer } from "redux";
import { RootState } from "redux/reducers";
import { solicitudes } from "api/solicitudes";
import {
  SolicitudAnalisisRequest,
  SolicitudAnalisisResponse,
  ModificarBancarizacionTitularResponse,
  SolicitudCalculoPrestamoRequest,
  OfertaResponse,
  ActualizarSituacionLaboralRequest,
  ActualizarSituacionLaboralResponse,
  SolicitudDomicilioRequest,
  DomicilioPersona,
  SituacionLaboral,
  ContactoRed,
  SolicitudEliminarTelefonoRequest,
  SolicitudAgregarDatosContactoRequest,
  SolicitudEliminarContactoRedRequest,
  SolicitudVehiculoRequest,
  ReformularSolicitudRequest,
  SolicitudDiferenciaNoFinanciada,
} from "api/types/originacion/services/analisisSolicitudTypes";

import { IListItem } from "api/types/originacion/commonTypes";
import dateToString from "libs/dateToString";

import {
  TipoRelacionPersona,
  EEstadoValidacionTelefono,
} from "api/types/originacion/businessTypes";
import { Cotizacion } from "api/types/originacion/services/segurosTypes";
import { solicitudesSeguros } from "api/solicitudesSeguros";

export const CARD_IDS = {
  titularDatosPersonales: "titular/datosPersonales",
  titularDomicilio: "titular/domicilio",
  titularSituacionLaboral: "titular/situacionLaboral",
  telefonos: "telefonos",
  redesSociales: "redesSociales",
  conyugeDatosPersonales: "conyuge/datosPersonales",
  conyugeDomicilio: "conyuge/domicilio",
  conyugeSituacionLaboral: "conyuge/situacionLaboral",
  codeudorDatosPersonales: "codeudor/datosPersonales",
  codeudorDomicilio: "codeudor/domicilio",
  codeudorSituacionLaboral: "codeudor/situacionLaboral",
  conyugeCodeudorDatosPersonales: "conyugeCodeudor/datosPersonales",
  conyugeCodeudorDomicilio: "conyugeCodeudor/domicilio",
  conyugeCodeudorSituacionLaboral: "conyugeCodeudor/situacionLaboral",
  automovil: "automovil",
  seguro: "seguro",
  operacion: "operacion/datosCredito",
  diferenciaNoFinanciada: "operacion/diferenciaNoFinanciada",
};

interface IUpdateDatosPersonalesCompleted {
  modificarBancarizacion?: ModificarBancarizacionTitularResponse;
  solicitudId: number;
  cuil: string;
  nacionalidad: number;
  fechaNacimiento: string;
  tieneLicenciaConducir: boolean;
}

interface IActions {
  analisis: {
    vt: {
      clear: () => void;
      //
      getSolicitudInited: (props: any) => void;
      getSolicitudCompleted: (response: SolicitudAnalisisResponse) => void;
      getSolicitudFailed: (error: any) => void;
      //Titular, Datos personales
      unCheckTitularDatosPersonales: () => void;
      updateTitularDatosPersonalesInited: (props: any) => void;
      updateTitularDatosPersonalesCompleted: (
        result: IUpdateDatosPersonalesCompleted
      ) => void;
      updateTitularDatosPersonalesFailed: (error: any) => void;
      //Domicilio
      unCheckTitularDomicilio: () => void;
      updateTitularDomicilioInited: (props: any) => void;
      updateTitularDomicilioCompleted: (
        result: SolicitudDomicilioRequest
      ) => void;
      updateTitularDomicilioFailed: (error: any) => void;
      //Sit.Laboral
      unCheckTitularSituacionLaboral: () => void;
      updateTitularSituacionLaboralInited: (props: any) => void;
      updateTitularSituacionLaboralCompleted: (
        request: ActualizarSituacionLaboralRequest,
        response: ActualizarSituacionLaboralResponse
      ) => void;
      updateTitularSituacionLaboralFailed: (error: any) => void;
      //Telefonos
      addTelefonoInited: (props: any) => void;
      addTelefonoCompleted: (
        result: SolicitudAgregarDatosContactoRequest
      ) => void;
      addTelefonoFailed: (error: any) => void;
      deleteTelefonoInited: (props: any) => void;
      deleteTelefonoCompleted: (
        result: SolicitudEliminarTelefonoRequest
      ) => void;
      deleteTelefonoFailed: (error: any) => void;
      //RedesSociales
      addRedSocialInited: (props: any) => void;
      addRedSocialCompleted: (
        result: SolicitudAgregarDatosContactoRequest
      ) => void;
      addRedSocialFailed: (error: any) => void;
      deleteRedSocialInited: (props: any) => void;
      deleteRedSocialCompleted: (
        result: SolicitudEliminarContactoRedRequest
      ) => void;
      deleteRedSocialFailed: (error: any) => void;
      //Vehiculo, auto

      //Operacion
      setOperacionPlan: (value: IListItem) => void;
      setOperacionPlazo: (value: IListItem) => void;
      calcularPrestamoInited: (props: any) => void;
      calcularPrestamoCompleted: (response: OfertaResponse) => void;
      calcularPrestamoFailed: (error: any) => void;
      setCapitalAFinanciar: (value: number) => void;
      reformularSolicitudInited: (props: any) => void;
      reformularSolicitudCompleted: () => void;
      reformularSolicitudFailed: (error: any) => void;
      unCheckOperacion: () => void;
      checkOperacion: () => void;
      //DiferenciaNoFinanciada
      unCheckDiferenciaNoFinanciada: () => void;
      updateDiferenciaNoFinanciadaInited: (props: any) => void;
      updateDiferenciaNoFinanciadaCompleted: (
        request: SolicitudDiferenciaNoFinanciada
      ) => void;
      updateDiferenciaNoFinanciadaFailed: (error: any) => void;
      //Conyuge, Datos personales
      unCheckConyugeDatosPersonales: () => void;
      updateConyugeDatosPersonalesInited: (props: any) => void;
      updateConyugeDatosPersonalesCompleted: (
        result: IUpdateDatosPersonalesCompleted
      ) => void;
      updateConyugeDatosPersonalesFailed: (error: any) => void;
      //Conyuge, Domicilio
      unCheckConyugeDomicilio: () => void;
      updateConyugeDomicilioInited: (props: any) => void;
      updateConyugeDomicilioCompleted: (
        result: SolicitudDomicilioRequest
      ) => void;
      updateConyugeDomicilioFailed: (error: any) => void;
      //Conyuge, Sit.Laboral
      unCheckConyugeSituacionLaboral: () => void;
      updateConyugeSituacionLaboralInited: (props: any) => void;
      updateConyugeSituacionLaboralCompleted: (
        result: ActualizarSituacionLaboralRequest
      ) => void;
      updateConyugeSituacionLaboralFailed: (error: any) => void;

      //seguros
      updateSeguroInited: (props: any) => void;
      updateSeguroCompleted: (poliza: Poliza) => void;
      updateSeguroFailed: (error: any) => void;
      invalidateSeguro: () => void;
      //Codeudor, Datos personales
      unCheckCodeudorDatosPersonales: () => void;
      updateCodeudorDatosPersonalesInited: (props: any) => void;
      updateCodeudorDatosPersonalesCompleted: (
        result: IUpdateDatosPersonalesCompleted
      ) => void;
      updateCodeudorDatosPersonalesFailed: (error: any) => void;
      //Codeudor, Domicilio
      unCheckCodeudorDomicilio: () => void;
      updateCodeudorDomicilioInited: (props: any) => void;
      updateCodeudorDomicilioCompleted: (
        result: SolicitudDomicilioRequest
      ) => void;
      updateCodeudorDomicilioFailed: (error: any) => void;
      //Codeudor, Sit.Laboral
      unCheckCodeudorSituacionLaboral: () => void;
      updateCodeudorSituacionLaboralInited: (props: any) => void;
      updateCodeudorSituacionLaboralCompleted: (
        result: ActualizarSituacionLaboralRequest
      ) => void;
      updateCodeudorSituacionLaboralFailed: (error: any) => void;
      //ConyugeCodeudor, Datos personales
      unCheckConyugeCodeudorDatosPersonales: () => void;
      updateConyugeCodeudorDatosPersonalesInited: (props: any) => void;
      updateConyugeCodeudorDatosPersonalesCompleted: (
        result: IUpdateDatosPersonalesCompleted
      ) => void;
      updateConyugeCodeudorDatosPersonalesFailed: (error: any) => void;
      //ConyugeCodeudor, Domicilio
      unCheckConyugeCodeudorDomicilio: () => void;
      updateConyugeCodeudorDomicilioInited: (props: any) => void;
      updateConyugeCodeudorDomicilioCompleted: (
        result: SolicitudDomicilioRequest
      ) => void;
      updateConyugeCodeudorDomicilioFailed: (error: any) => void;
      //ConyugeCodeudor, Sit.Laboral
      unCheckConyugeCodeudorSituacionLaboral: () => void;
      updateConyugeCodeudorSituacionLaboralInited: (props: any) => void;
      updateConyugeCodeudorSituacionLaboralCompleted: (
        request: ActualizarSituacionLaboralRequest
      ) => void;
      updateConyugeCodeudorSituacionLaboralFailed: (error: any) => void;
      //Vehiculo
      unCheckVehiculo: () => void;
      updateVehiculoInited: (props: any) => void;
      updateVehiculoCompleted: (request: SolicitudVehiculoRequest) => void;
      updateVehiculoFailed: (error: any) => void;
    };
  };
}

const {
  analisis: {
    vt: {
      clear,
      //
      getSolicitudInited,
      getSolicitudCompleted,
      getSolicitudFailed,
      //Titular, Datos personales
      unCheckTitularDatosPersonales,
      updateTitularDatosPersonalesInited,
      updateTitularDatosPersonalesCompleted,
      updateTitularDatosPersonalesFailed,
      //Domicilio
      unCheckTitularDomicilio,
      updateTitularDomicilioInited,
      updateTitularDomicilioCompleted,
      updateTitularDomicilioFailed,
      //Sit.Laboral
      unCheckTitularSituacionLaboral,
      updateTitularSituacionLaboralInited,
      updateTitularSituacionLaboralCompleted,
      updateTitularSituacionLaboralFailed,
      //Telefonos
      addTelefonoInited,
      addTelefonoCompleted,
      addTelefonoFailed,
      deleteTelefonoInited,
      deleteTelefonoCompleted,
      deleteTelefonoFailed,
      //RedesSociales
      addRedSocialInited,
      addRedSocialCompleted,
      addRedSocialFailed,
      deleteRedSocialInited,
      deleteRedSocialCompleted,
      deleteRedSocialFailed,
      //Vehiculo, auto
      unCheckVehiculo,
      updateVehiculoInited,
      updateVehiculoCompleted,
      updateVehiculoFailed,
      //Operacion
      setOperacionPlan,
      setOperacionPlazo,
      calcularPrestamoInited,
      calcularPrestamoCompleted,
      calcularPrestamoFailed,
      setCapitalAFinanciar,
      reformularSolicitudInited,
      reformularSolicitudCompleted,
      reformularSolicitudFailed,
      unCheckOperacion,
      checkOperacion,
      //Diferencia no financiada
      unCheckDiferenciaNoFinanciada,
      updateDiferenciaNoFinanciadaInited,
      updateDiferenciaNoFinanciadaCompleted,
      updateDiferenciaNoFinanciadaFailed,
      //Conyuge, Datos personales
      unCheckConyugeDatosPersonales,
      updateConyugeDatosPersonalesInited,
      updateConyugeDatosPersonalesCompleted,
      updateConyugeDatosPersonalesFailed,
      //Conyuge, Domicilio
      unCheckConyugeDomicilio,
      updateConyugeDomicilioInited,
      updateConyugeDomicilioCompleted,
      updateConyugeDomicilioFailed,
      //Conyuge, Sit.Laboral
      unCheckConyugeSituacionLaboral,
      updateConyugeSituacionLaboralInited,
      updateConyugeSituacionLaboralCompleted,
      updateConyugeSituacionLaboralFailed,
      //seguros
      updateSeguroInited,
      updateSeguroCompleted,
      updateSeguroFailed,
      invalidateSeguro,
      //Codeudor, Datos personales
      unCheckCodeudorDatosPersonales,
      updateCodeudorDatosPersonalesInited,
      updateCodeudorDatosPersonalesCompleted,
      updateCodeudorDatosPersonalesFailed,
      //Codeudor, Domicilio
      unCheckCodeudorDomicilio,
      updateCodeudorDomicilioInited,
      updateCodeudorDomicilioCompleted,
      updateCodeudorDomicilioFailed,
      //Codeudor, Sit.Laboral
      unCheckCodeudorSituacionLaboral,
      updateCodeudorSituacionLaboralInited,
      updateCodeudorSituacionLaboralCompleted,
      updateCodeudorSituacionLaboralFailed,
      //ConyugeCodeudor, Datos personales
      unCheckConyugeCodeudorDatosPersonales,
      updateConyugeCodeudorDatosPersonalesInited,
      updateConyugeCodeudorDatosPersonalesCompleted,
      updateConyugeCodeudorDatosPersonalesFailed,
      //ConyugeCodeudor, Domicilio
      unCheckConyugeCodeudorDomicilio,
      updateConyugeCodeudorDomicilioInited,
      updateConyugeCodeudorDomicilioCompleted,
      updateConyugeCodeudorDomicilioFailed,
      //ConyugeCodeudor, Sit.Laboral
      unCheckConyugeCodeudorSituacionLaboral,
      updateConyugeCodeudorSituacionLaboralInited,
      updateConyugeCodeudorSituacionLaboralCompleted,
      updateConyugeCodeudorSituacionLaboralFailed,
    },
  },
}: IActions = createActions({
  analisis: {
    vt: {
      clear: () => ({}),
      //
      getSolicitudInited: (props: any) => ({ props }),
      getSolicitudCompleted: (response: SolicitudAnalisisResponse) => ({
        ...response,
      }),
      getSolicitudFailed: (error: any) => ({ error }),
      //Titular, Datos personales
      unCheckTitularDatosPersonales: () => ({}),
      updateTitularDatosPersonalesInited: (props: any) => ({ props }),
      updateTitularDatosPersonalesCompleted: (
        result: IUpdateDatosPersonalesCompleted
      ) => ({ ...result }),
      updateTitularDatosPersonalesFailed: (error: any) => ({ error }),
      //Domicilio
      unCheckTitularDomicilio: () => ({}),
      updateTitularDomicilioInited: (props: any) => ({ props }),
      updateTitularDomicilioCompleted: (result: SolicitudDomicilioRequest) => ({
        ...result,
      }),
      updateTitularDomicilioFailed: (error: any) => ({ error }),
      //Sit.Laboral
      unCheckTitularSituacionLaboral: () => ({}),
      updateTitularSituacionLaboralInited: (props: any) => ({ props }),
      updateTitularSituacionLaboralCompleted: (
        request: ActualizarSituacionLaboralRequest,
        response: ActualizarSituacionLaboralResponse
      ) => ({
        request: { ...request },
        response: { ...response },
      }),
      updateTitularSituacionLaboralFailed: (error: any) => ({ error }),
      //Telefonos
      addTelefonoInited: (props: any) => ({ props }),
      addTelefonoCompleted: (result: SolicitudAgregarDatosContactoRequest) => ({
        ...result,
      }),
      addTelefonoFailed: (error: any) => ({ error }),
      deleteTelefonoInited: (props: any) => ({ props }),
      deleteTelefonoCompleted: (result: SolicitudEliminarTelefonoRequest) => ({
        ...result,
      }),
      deleteTelefonoFailed: (error: any) => ({ error }),
      //RedesSociales
      addRedSocialInited: (props: any) => ({ props }),
      addRedSocialCompleted: (
        result: SolicitudAgregarDatosContactoRequest
      ) => ({
        ...result,
      }),
      addRedSocialFailed: (error: any) => ({ error }),
      deleteRedSocialInited: (props: any) => ({ props }),
      deleteRedSocialCompleted: (
        result: SolicitudEliminarContactoRedRequest
      ) => ({
        ...result,
      }),
      deleteRedSocialFailed: (error: any) => ({ error }),
      //Vehiculo, auto

      //Operacion
      setOperacionPlan: (value: IListItem) => ({ ...value }),
      setOperacionPlazo: (value: IListItem) => ({ ...value }),
      calcularPrestamoInited: (props: any) => ({ props }),
      calcularPrestamoCompleted: (response: OfertaResponse) => ({
        ...response,
      }),
      calcularPrestamoFailed: (error: any) => ({ error }),
      setCapitalAFinanciar: (value: number) => value,
      reformularSolicitudInited: (props: any) => ({ props }),
      reformularSolicitudCompleted: () => ({}),
      reformularSolicitudFailed: (error: any) => ({ error }),
      unCheckOperacion: () => ({}),
      checkOperacion: () => ({}),
      //Diferencia no Financiada
      unCheckDiferenciaNoFinanciada: () => ({}),
      updateDiferenciaNoFinanciadaInited: (props: any) => ({ props }),
      updateDiferenciaNoFinanciadaCompleted: (
        request: SolicitudDiferenciaNoFinanciada
      ) => ({ request: { ...request } }),
      updateDiferenciaNoFinanciadaFailed: (error: any) => ({ error }),
      //Conyuge, Datos personales
      unCheckConyugeDatosPersonales: () => ({}),
      updateConyugeDatosPersonalesInited: (props: any) => ({ props }),
      updateConyugeDatosPersonalesCompleted: (
        result: IUpdateDatosPersonalesCompleted
      ) => ({ ...result }),
      updateConyugeDatosPersonalesFailed: (error: any) => ({ error }),
      //Conyuge, Domicilio
      unCheckConyugeDomicilio: () => ({}),
      updateConyugeDomicilioInited: (props: any) => ({ props }),
      updateConyugeDomicilioCompleted: (result: SolicitudDomicilioRequest) => ({
        ...result,
      }),
      updateConyugeDomicilioFailed: (error: any) => ({ error }),
      //Conyuge, Sit.Laboral
      unCheckConyugeSituacionLaboral: () => ({}),
      updateConyugeSituacionLaboralInited: (props: any) => ({ props }),
      updateConyugeSituacionLaboralCompleted: (
        result: ActualizarSituacionLaboralRequest
      ) => ({
        ...result,
      }),
      updateConyugeSituacionLaboralFailed: (error: any) => ({ error }),
      //seguros
      updateSeguroInited: (props: any) => ({ props }),
      updateSeguroCompleted: (poliza: Poliza) => poliza,
      updateSeguroFailed: (error: any) => ({ error }),
      invalidateSeguro: () => ({}),
      //Codeudor, Datos personales
      unCheckCodeudorDatosPersonales: () => ({}),
      updateCodeudorDatosPersonalesInited: (props: any) => ({ props }),
      updateCodeudorDatosPersonalesCompleted: (
        result: IUpdateDatosPersonalesCompleted
      ) => ({ ...result }),
      updateCodeudorDatosPersonalesFailed: (error: any) => ({ error }),
      //Codeudor, Domicilio
      unCheckCodeudorDomicilio: () => ({}),
      updateCodeudorDomicilioInited: (props: any) => ({ props }),
      updateCodeudorDomicilioCompleted: (
        result: SolicitudDomicilioRequest
      ) => ({
        ...result,
      }),
      updateCodeudorDomicilioFailed: (error: any) => ({ error }),
      //Codeudor, Sit.Laboral
      unCheckCodeudorSituacionLaboral: () => ({}),
      updateCodeudorSituacionLaboralInited: (props: any) => ({ props }),
      updateCodeudorSituacionLaboralCompleted: (
        result: ActualizarSituacionLaboralRequest
      ) => ({ ...result }),
      updateCodeudorSituacionLaboralFailed: (error: any) => ({ error }),
      //ConyugeCodeudor, Datos personales
      unCheckConyugeCodeudorDatosPersonales: () => ({}),
      updateConyugeCodeudorDatosPersonalesInited: (props: any) => ({ props }),
      updateConyugeCodeudorDatosPersonalesCompleted: (
        result: IUpdateDatosPersonalesCompleted
      ) => ({ ...result }),
      updateConyugeCodeudorDatosPersonalesFailed: (error: any) => ({ error }),
      //ConyugeCodeudor, Domicilio
      unCheckConyugeCodeudorDomicilio: () => ({}),
      updateConyugeCodeudorDomicilioInited: (props: any) => ({ props }),
      updateConyugeCodeudorDomicilioCompleted: (
        result: SolicitudDomicilioRequest
      ) => ({
        ...result,
      }),
      updateConyugeCodeudorDomicilioFailed: (error: any) => ({ error }),
      //ConyugeCodeudor, Sit.Laboral
      unCheckConyugeCodeudorSituacionLaboral: () => ({}),
      updateConyugeCodeudorSituacionLaboralInited: (props: any) => ({ props }),
      updateConyugeCodeudorSituacionLaboralCompleted: (
        request: ActualizarSituacionLaboralRequest
      ) => ({ ...request }),
      updateConyugeCodeudorSituacionLaboralFailed: (error: any) => ({ error }),
      //Vehiculo
      unCheckVehiculo: () => ({}),
      updateVehiculoInited: (props: any) => ({ props }),
      updateVehiculoCompleted: (request: SolicitudVehiculoRequest) => ({
        ...request,
      }),
      updateVehiculoFailed: (error: any) => ({ error }),
    },
  },
});
interface IUpdateDatosPersonales {
  solicitudId: number;
  cuil: string;
  nacionalidad: number;
  fechaNacimiento: string;
  tieneLicenciaConducir: boolean;
  bancarizado?: boolean;
}
//vt
const getSolicitud = (request: SolicitudAnalisisRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(getSolicitudInited(request));
        const response = await solicitudes(accessToken).getSolicitud(request);
        dispatch(getSolicitudCompleted(response));
      }
    } catch (error) {
      console.error(error);
      dispatch(getSolicitudFailed(error));
    }
  };
};

interface Poliza extends Cotizacion {
  cotizacionId: string;
  companiaId: number;
  compania: string;
}

const updateSeguro = (solicitudId: number, poliza: Poliza) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateSeguroInited({ ...poliza, solicitudId }));
        await solicitudesSeguros(accessToken).postCotizacion({
          id: solicitudId,
          cotizacionId: poliza.cotizacionId,
          cotizacionPlanId: poliza.cotizacionPlanId,
          tipoPolizaId: poliza.tipoPolizaId,
          pendiente: false,
        });
        dispatch(updateSeguroCompleted(poliza));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateSeguroFailed(error));
    }
  };
};

const calcularPrestamo = (request: SolicitudCalculoPrestamoRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(calcularPrestamoInited(request));

        const response = await solicitudes(accessToken).getCalculoPrestamo(
          request
        );
        dispatch(calcularPrestamoCompleted(response));
      }
    } catch (error) {
      console.error(error);
      dispatch(calcularPrestamoFailed(error));
    }
  };
};

//TITULAR
const updateAndCheckTitularDatosPersonales = ({
  solicitudId,
  cuil,
  nacionalidad,
  fechaNacimiento,
  tieneLicenciaConducir,
  bancarizado,
}: IUpdateDatosPersonales) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (!accessToken) throw new Error("No access token available");

      dispatch(
        updateTitularDatosPersonalesInited({
          solicitudId,
          cuil,
          nacionalidad,
          fechaNacimiento,
          tieneLicenciaConducir,
          bancarizado,
        })
      );

      let modificarBancarizacion;
      const prevBancarizado =
        getState().vtSolicitudReducer.solicitud?.titular.datosPersonales
          .bancarizado;
      if (bancarizado !== prevBancarizado) {
        modificarBancarizacion = await solicitudes(
          accessToken
        ).postTitularBancarizado({
          id: solicitudId,
        });
      }

      await solicitudes(accessToken).postCorregirDatosPersona({
        tipoPersona: TipoRelacionPersona.Titular,
        id: solicitudId,
        cuil,
        nacionalidad,
        fechaNacimiento,
        tieneLicenciaConducir,
      });

      dispatch(
        updateTitularDatosPersonalesCompleted({
          modificarBancarizacion,
          solicitudId,
          cuil,
          nacionalidad,
          fechaNacimiento,
          tieneLicenciaConducir,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(updateTitularDatosPersonalesFailed(error));
    }
  };
};
const updateAndCheckTitularDomicilio = (request: SolicitudDomicilioRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateTitularDomicilioInited({ request }));

        const solicitud = getState().vtSolicitudReducer.solicitud;

        if (
          solicitud?.titular.domicilio.codigoPostal !== request.codigoPostal
        ) {
          dispatch(invalidateSeguro());
        }

        solicitudes(accessToken).postActualizarDomicilio(request);
        dispatch(updateTitularDomicilioCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateTitularDomicilioFailed(error));
    }
  };
};
const updateAndCheckTitularSituacionLaboral = (
  request: ActualizarSituacionLaboralRequest
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateTitularSituacionLaboralInited(request));
        const response = await solicitudes(
          accessToken
        ).postActualizarSituacionLaboral(request);
        dispatch(updateTitularSituacionLaboralCompleted(request, response));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateTitularSituacionLaboralFailed(error));
    }
  };
};

const addTelefono = (request: SolicitudAgregarDatosContactoRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(addTelefonoInited({ request }));
        solicitudes(accessToken).postAgregarDatosContacto(request);
        dispatch(addTelefonoCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(addTelefonoFailed(error));
    }
  };
};

const deleteTelefono = (request: SolicitudEliminarTelefonoRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(deleteTelefonoInited({ request }));
        solicitudes(accessToken).postEliminarContactoTelefono(request);
        dispatch(deleteTelefonoCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(deleteTelefonoFailed(error));
    }
  };
};

const addRedSocial = (request: SolicitudAgregarDatosContactoRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(addRedSocialInited({ request }));
        solicitudes(accessToken).postAgregarDatosContacto(request);
        dispatch(addRedSocialCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(addRedSocialFailed(error));
    }
  };
};

const deleteRedSocial = (request: SolicitudEliminarContactoRedRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(deleteRedSocialInited({ request }));
        solicitudes(accessToken).postEliminarContactoRed(request);
        dispatch(deleteRedSocialCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(deleteRedSocialFailed(error));
    }
  };
};

const updateAndCheckVehiculo = (request: SolicitudVehiculoRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateVehiculoInited({ request }));

        const solicitud = getState().vtSolicitudReducer.solicitud;

        const haCambiadoElVehiculo =
          solicitud?.vehiculo.datosVehiculo.codia !== request.codia ||
          solicitud?.vehiculo.datosVehiculo.anio !== request.anio ||
          solicitud?.vehiculo.datosVehiculo.usoComercial !==
            request.usoComercial;

        if (haCambiadoElVehiculo) {
          dispatch(invalidateSeguro());
        }

        await solicitudes(accessToken).postActualizarVehiculo(request);

        dispatch(updateVehiculoCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateVehiculoFailed(error));
    }
  };
};

/*const validatePlazo = (request: SolicitudPlazosPrestamoRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(validatePlazoInited(request));
        const selectedPlazo = getState().vtSolicitudReducer.solicitud?.operacion
          .plazo;
        const plazos = await solicitudes(accessToken).getPlazosPrestamo(
          request);
          dispatch(validatePlazoCompleted(request));
        }
      } catch (error) {
        console.error(error);
        dispatch(validatePlazoFailed(error));
      }
    };
  };*/

//CONYUGE
const updateAndCheckConyugeDatosPersonales = ({
  solicitudId,
  cuil,
  nacionalidad,
  fechaNacimiento,
  tieneLicenciaConducir,
}: IUpdateDatosPersonales) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (!accessToken) throw new Error("No access token available");

      dispatch(
        updateConyugeDatosPersonalesInited({
          solicitudId,
          cuil,
          nacionalidad,
          fechaNacimiento,
          tieneLicenciaConducir,
        })
      );

      await solicitudes(accessToken).postCorregirDatosPersona({
        tipoPersona: TipoRelacionPersona.Conyuge,
        id: solicitudId,
        cuil,
        nacionalidad,
        fechaNacimiento,
        tieneLicenciaConducir,
      });

      dispatch(
        updateConyugeDatosPersonalesCompleted({
          solicitudId,
          cuil,
          nacionalidad,
          fechaNacimiento,
          tieneLicenciaConducir,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(updateConyugeDatosPersonalesFailed(error));
    }
  };
};

const updateAndCheckConyugeDomicilio = (request: SolicitudDomicilioRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateConyugeDomicilioInited({ request }));
        solicitudes(accessToken).postActualizarDomicilio(request);
        dispatch(updateConyugeDomicilioCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateConyugeDomicilioFailed(error));
    }
  };
};
const updateAndCheckConyugeSituacionLaboral = (
  request: ActualizarSituacionLaboralRequest
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateConyugeSituacionLaboralInited(request));
        await solicitudes(accessToken).postActualizarSituacionLaboral(request);
        dispatch(updateConyugeSituacionLaboralCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateConyugeSituacionLaboralFailed(error));
    }
  };
};
//CODEUDOR
const updateAndCheckCodeudorDatosPersonales = ({
  solicitudId,
  cuil,
  nacionalidad,
  fechaNacimiento,
  tieneLicenciaConducir,
}: IUpdateDatosPersonales) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (!accessToken) throw new Error("No access token available");

      dispatch(
        updateCodeudorDatosPersonalesInited({
          solicitudId,
          cuil,
          nacionalidad,
          fechaNacimiento,
          tieneLicenciaConducir,
        })
      );

      await solicitudes(accessToken).postCorregirDatosPersona({
        tipoPersona: TipoRelacionPersona.Codeudor,
        id: solicitudId,
        cuil,
        nacionalidad,
        fechaNacimiento,
        tieneLicenciaConducir,
      });
      dispatch(
        updateCodeudorDatosPersonalesCompleted({
          solicitudId,
          cuil,
          nacionalidad,
          fechaNacimiento,
          tieneLicenciaConducir,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(updateCodeudorDatosPersonalesFailed(error));
    }
  };
};
const updateAndCheckCodeudorDomicilio = (
  request: SolicitudDomicilioRequest
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateCodeudorDomicilioInited({ request }));
        solicitudes(accessToken).postActualizarDomicilio(request);
        dispatch(updateCodeudorDomicilioCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateCodeudorDomicilioFailed(error));
    }
  };
};
const updateAndCheckCodeudorSituacionLaboral = (
  request: ActualizarSituacionLaboralRequest
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateCodeudorSituacionLaboralInited(request));
        solicitudes(accessToken).postActualizarSituacionLaboral(request);
        dispatch(updateCodeudorSituacionLaboralCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateCodeudorSituacionLaboralFailed(error));
    }
  };
};
//CONYUGE CODEUDOR
const updateAndCheckConyugeCodeudorDatosPersonales = ({
  solicitudId,
  cuil,
  nacionalidad,
  fechaNacimiento,
  tieneLicenciaConducir,
}: IUpdateDatosPersonales) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (!accessToken) throw new Error("No access token available");

      dispatch(
        updateConyugeCodeudorDatosPersonalesInited({
          solicitudId,
          cuil,
          nacionalidad,
          fechaNacimiento,
          tieneLicenciaConducir,
        })
      );

      await solicitudes(accessToken).postCorregirDatosPersona({
        tipoPersona: TipoRelacionPersona.ConyugeCodeudor,
        id: solicitudId,
        cuil,
        nacionalidad,
        fechaNacimiento,
        tieneLicenciaConducir,
      });

      dispatch(
        updateConyugeCodeudorDatosPersonalesCompleted({
          solicitudId,
          cuil,
          nacionalidad,
          fechaNacimiento,
          tieneLicenciaConducir,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(updateConyugeCodeudorDatosPersonalesFailed(error));
    }
  };
};
const updateAndCheckConyugeCodeudorDomicilio = (
  request: SolicitudDomicilioRequest
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateConyugeCodeudorDomicilioInited({ request }));
        solicitudes(accessToken).postActualizarDomicilio(request);
        dispatch(updateConyugeCodeudorDomicilioCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateConyugeCodeudorDomicilioFailed(error));
    }
  };
};
const updateAndCheckConyugeCodeudorSituacionLaboral = (
  request: ActualizarSituacionLaboralRequest
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateConyugeCodeudorSituacionLaboralInited(request));
        solicitudes(accessToken).postActualizarSituacionLaboral(request);
        dispatch(updateConyugeCodeudorSituacionLaboralCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateConyugeCodeudorSituacionLaboralFailed(error));
    }
  };
};

//OPERACION
const reformularSolicitud = (request: ReformularSolicitudRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(reformularSolicitudInited(request));
        await solicitudes(accessToken).postReformularSolicitud(request);
        dispatch(reformularSolicitudCompleted());
      }
    } catch (error) {
      console.error(error);
      dispatch(reformularSolicitudFailed(error));
    }
  };
};

//DIFERENCIA NO FINANCIADA
const updateAndCheckDiferenciaNoFinanciada = (
  request: SolicitudDiferenciaNoFinanciada
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateDiferenciaNoFinanciadaInited(request));
        await solicitudes(accessToken).postDiferenciaNoFinanciada({
          id: request.id,
          efectivo: request.efectivo,
          vendeVehiculo: request.vendeVehiculo,
          vehiculoVendidoCodia: request.vehiculoVendidoCodia,
          vehiculoVendidoAnio: request.vehiculoVendidoAnio,
          otraDiferencia: request.otraDiferencia,
        });
        dispatch(updateDiferenciaNoFinanciadaCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateDiferenciaNoFinanciadaFailed(error));
    }
  };
};

interface IFieldsFlag {
  [fieldName: string]: boolean;
}

interface IState {
  error: any | null;
  isLoading: boolean;
  solicitud: SolicitudAnalisisResponse | null;
  solicitudMirror: SolicitudAnalisisResponse | null;
  cardsChecked: string[];
  vehiculoAutoEditing: IFieldsFlag;
  vehiculoAutoChecked: IFieldsFlag;
}

const initialState: IState = {
  error: null,
  isLoading: false,
  solicitud: null,
  solicitudMirror: null,
  cardsChecked: [CARD_IDS.telefonos, CARD_IDS.redesSociales, CARD_IDS.seguro],
  vehiculoAutoEditing: {},
  vehiculoAutoChecked: {},
};

interface IAction<T> {
  payload: T;
}

const vtSolicitudReducer: Reducer<IState> = handleActions(
  {
    //#region VT
    [clear as any]: () => {
      return {
        ...initialState,
      };
    },
    [getSolicitudInited as any]: () => {
      return { ...initialState, isLoading: true };
    },
    [getSolicitudCompleted as any]: (
      state: IState,
      action: IAction<SolicitudAnalisisResponse>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(action.payload)
      ) as SolicitudAnalisisResponse;
      solicitud.titular.datosPersonales.fechaNacimiento =
        dateToString(solicitud.titular.datosPersonales.fechaNacimiento) ??
        solicitud.titular.datosPersonales.fechaNacimiento;

      const mirror = JSON.parse(
        JSON.stringify(solicitud)
      ) as SolicitudAnalisisResponse;

      return {
        ...initialState,
        isLoading: false,
        solicitud: solicitud,
        solicitudMirror: mirror,
      };
    },
    [getSolicitudFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    },
    //#endregion
    //#region TITULAR-DATOS PERSONALES
    [unCheckTitularDatosPersonales as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.titularDatosPersonales))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.titularDatosPersonales
        );

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateTitularDatosPersonalesInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateTitularDatosPersonalesCompleted as any]: (
      state: IState,
      action: IAction<IUpdateDatosPersonalesCompleted>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      if (action.payload.modificarBancarizacion) {
        solicitud.titular.datosPersonales.bancarizado = true;
        solicitud.titular.datosPersonales.calificacion =
          action.payload.modificarBancarizacion.clasificacion;
        solicitud.requiereReformular =
          action.payload.modificarBancarizacion.requiereReformular;
      }

      solicitud.titular.datosPersonales.cuil = action.payload.cuil;
      solicitud.titular.datosPersonales.fechaNacimiento =
        action.payload.fechaNacimiento;
      solicitud.titular.datosPersonales.tieneLicenciaConducir =
        action.payload.tieneLicenciaConducir;
      solicitud.titular.datosPersonales.nacionalidad =
        action.payload.nacionalidad;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.titularDatosPersonales))
        cardsChecked = [...state.cardsChecked, CARD_IDS.titularDatosPersonales];

      return {
        ...state,
        solicitud: solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateTitularDatosPersonalesFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    //#endregion
    //#region TITULAR-SITUACION LABORAL
    [unCheckTitularSituacionLaboral as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.titularSituacionLaboral))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.titularSituacionLaboral
        );
      return {
        ...state,
        cardsChecked,
      };
    },
    [updateTitularSituacionLaboralInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateTitularSituacionLaboralCompleted as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      const request = action.payload
        .request as ActualizarSituacionLaboralRequest;
      const response = action.payload
        .response as ActualizarSituacionLaboralResponse;
      const situacionLaboral: SituacionLaboral = {
        actividad: request.actividad,
        antiguedad: request.antiguedad,
        actividadDeclarada: request.actividadDeclarada,
        ingresosDeclarados: request.ingresosDeclarados,
        antiguedadDeclarada: request.antiguedadDeclarada,
        profesion: request.profesion,
        puestoJerarquico: request.puestoJerarquico,
        rubroEmpleoPublico: request.rubroEmpleoPublico,
      };
      solicitud.titular.situacionLaboral = situacionLaboral;
      solicitud.requiereReformular = response.requiereReformular;
      solicitud.titular.datosPersonales.calificacion =
        response.clasificacionTitular;
      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.titularSituacionLaboral))
        cardsChecked = [
          ...state.cardsChecked,
          CARD_IDS.titularSituacionLaboral,
        ];
      return {
        ...state,
        cardsChecked,
        isLoading: false,
        solicitud: solicitud,
      };
    },
    [updateTitularSituacionLaboralFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    //#endregion
    //#region TITULAR-DOMICILIO
    [unCheckTitularDomicilio as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.titularDomicilio))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.titularDomicilio
        );

      return {
        ...state,
        cardsChecked,
      };
    },
    [updateTitularDomicilioInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateTitularDomicilioCompleted as any]: (
      state: IState,
      action: IAction<SolicitudDomicilioRequest>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      const { direccion, provincia, localidad, codigoPostal } = action.payload;
      const domicilioPersona: DomicilioPersona = {
        direccion: direccion,
        provincia: provincia,
        localidad: localidad,
        codigoPostal: codigoPostal,
      };
      solicitud.titular.domicilio = domicilioPersona;
      if (solicitud.conyuge) solicitud.conyuge.domicilio = domicilioPersona;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.titularDomicilio))
        cardsChecked = [...state.cardsChecked, CARD_IDS.titularDomicilio];

      return {
        ...state,
        solicitud: solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateTitularDomicilioFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    //#endregion

    //#region DATOS CONTACTO
    [addTelefonoInited as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [addTelefonoCompleted as any]: (
      state: IState,
      action: IAction<SolicitudAgregarDatosContactoRequest>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      if (action.payload.telefonos)
        solicitud.telefonos.push(action.payload.telefonos[0]);

      return {
        ...state,
        solicitud,
        isLoading: false,
      };
    },
    [addTelefonoFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    [deleteTelefonoInited as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [deleteTelefonoCompleted as any]: (
      state: IState,
      action: IAction<SolicitudEliminarTelefonoRequest>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      let index = solicitud.telefonos.findIndex(
        (t) => t.numero === action.payload.numero
      );
      if (index !== -1)
        solicitud.telefonos[index].estado = EEstadoValidacionTelefono.No_Valido;

      return {
        ...state,
        solicitud,
        isLoading: false,
      };
    },
    [deleteTelefonoFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    [addRedSocialInited as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [addRedSocialCompleted as any]: (
      state: IState,
      action: IAction<ContactoRed>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      if (action.payload.usuario)
        solicitud.contactosRed.push({
          redInternet: action.payload.redInternet,
          usuario: action.payload.usuario,
        });

      return {
        ...state,
        solicitud,
        isLoading: false,
      };
    },
    [addRedSocialFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    [deleteRedSocialInited as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [deleteRedSocialCompleted as any]: (
      state: IState,
      action: IAction<SolicitudEliminarContactoRedRequest>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      solicitud.contactosRed = solicitud.contactosRed.filter(
        (r) => r.usuario !== action.payload.usuario
      );
      return {
        ...state,
        solicitud,
        isLoading: false,
      };
    },
    [deleteRedSocialFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    //#endregion

    //#region CONYUGE-DATOS PERSONALES
    [unCheckConyugeDatosPersonales as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.conyugeDatosPersonales))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.conyugeDatosPersonales
        );

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateConyugeDatosPersonalesInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateConyugeDatosPersonalesCompleted as any]: (
      state: IState,
      action: IAction<IUpdateDatosPersonalesCompleted>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      solicitud.conyuge.datosPersonales.cuil = action.payload.cuil;
      solicitud.conyuge.datosPersonales.fechaNacimiento =
        action.payload.fechaNacimiento;
      solicitud.conyuge.datosPersonales.tieneLicenciaConducir =
        action.payload.tieneLicenciaConducir;
      solicitud.conyuge.datosPersonales.nacionalidad =
        action.payload.nacionalidad;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.conyugeDatosPersonales))
        cardsChecked = [...state.cardsChecked, CARD_IDS.conyugeDatosPersonales];

      return {
        ...state,
        solicitud: solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateConyugeDatosPersonalesFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    //#endregion
    //#region CONYUGE-DOMICILIO
    [updateConyugeDomicilioInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateConyugeDomicilioCompleted as any]: (
      state: IState,
      action: IAction<SolicitudDomicilioRequest>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      const { direccion, provincia, localidad, codigoPostal } = action.payload;
      const domicilioPersona: DomicilioPersona = {
        direccion: direccion,
        provincia: provincia,
        localidad: localidad,
        codigoPostal: codigoPostal,
      };
      solicitud.conyuge.domicilio = domicilioPersona;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.conyugeDomicilio))
        cardsChecked = [...state.cardsChecked, CARD_IDS.conyugeDomicilio];

      return {
        ...state,
        solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateConyugeDomicilioFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [unCheckConyugeDomicilio as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.conyugeDomicilio))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.conyugeDomicilio
        );

      return {
        ...state,
        cardsChecked,
      };
    },
    //#endregion
    //#region CONYUGE-SITUACION LABORAL
    [unCheckConyugeSituacionLaboral as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.conyugeSituacionLaboral))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.conyugeSituacionLaboral
        );
      return {
        ...state,
        cardsChecked,
      };
    },
    [updateConyugeSituacionLaboralInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateConyugeSituacionLaboralCompleted as any]: (
      state: IState,
      action: IAction<ActualizarSituacionLaboralRequest>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      const request = action.payload;
      /* const response = action.payload
        .response as ActualizarSituacionLaboralResponse; */
      const situacionLaboral: SituacionLaboral = {
        actividad: request.actividad,
        antiguedad: request.antiguedad,
        actividadDeclarada: request.actividadDeclarada,
        ingresosDeclarados: request.ingresosDeclarados,
        antiguedadDeclarada: request.antiguedadDeclarada,
        profesion: request.profesion,
        puestoJerarquico: request.puestoJerarquico,
        rubroEmpleoPublico: request.rubroEmpleoPublico,
      };
      solicitud.conyuge.situacionLaboral = situacionLaboral;
      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.conyugeSituacionLaboral))
        cardsChecked = [
          ...state.cardsChecked,
          CARD_IDS.conyugeSituacionLaboral,
        ];
      return {
        ...state,
        cardsChecked,
        solicitud,
        isLoading: false,
      };
    },
    [updateConyugeSituacionLaboralFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    //#endregion

    //#region CODEUDOR-DATOS PERSONALES
    [unCheckCodeudorDatosPersonales as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.codeudorDatosPersonales))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.codeudorDatosPersonales
        );

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateCodeudorDatosPersonalesInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateCodeudorDatosPersonalesCompleted as any]: (
      state: IState,
      action: IAction<IUpdateDatosPersonalesCompleted>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      solicitud.codeudor.datosPersonales.cuil = action.payload.cuil;
      solicitud.codeudor.datosPersonales.fechaNacimiento =
        action.payload.fechaNacimiento;
      solicitud.codeudor.datosPersonales.tieneLicenciaConducir =
        action.payload.tieneLicenciaConducir;
      solicitud.codeudor.datosPersonales.nacionalidad =
        action.payload.nacionalidad;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.codeudorDatosPersonales))
        cardsChecked = [
          ...state.cardsChecked,
          CARD_IDS.codeudorDatosPersonales,
        ];

      return {
        ...state,
        solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateCodeudorDatosPersonalesFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    //#endregion
    //#region CODEUDOR-DOMICILIO
    [updateCodeudorDomicilioInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateCodeudorDomicilioCompleted as any]: (
      state: IState,
      action: IAction<SolicitudDomicilioRequest>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      const { direccion, provincia, localidad, codigoPostal } = action.payload;
      const domicilioPersona: DomicilioPersona = {
        direccion: direccion,
        provincia: provincia,
        localidad: localidad,
        codigoPostal: codigoPostal,
      };
      solicitud.codeudor.domicilio = domicilioPersona;
      if (solicitud.conyugeCodeudor)
        solicitud.conyugeCodeudor.domicilio = domicilioPersona;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.codeudorDomicilio))
        cardsChecked = [...state.cardsChecked, CARD_IDS.codeudorDomicilio];

      return {
        ...state,
        solicitud: solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateCodeudorDomicilioFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [unCheckCodeudorDomicilio as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.codeudorDomicilio))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.codeudorDomicilio
        );

      return {
        ...state,
        cardsChecked,
      };
    },
    //#endregion
    //#region CODEUDOR-SITUACION LABORAL
    [unCheckCodeudorSituacionLaboral as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.codeudorSituacionLaboral))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.codeudorSituacionLaboral
        );
      return {
        ...state,
        cardsChecked,
      };
    },
    [updateCodeudorSituacionLaboralInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateCodeudorSituacionLaboralCompleted as any]: (
      state: IState,
      action: IAction<ActualizarSituacionLaboralRequest>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      const request = action.payload;

      const situacionLaboral: SituacionLaboral = {
        actividad: request.actividad,
        antiguedad: request.antiguedad,
        actividadDeclarada: request.actividadDeclarada,
        ingresosDeclarados: request.ingresosDeclarados,
        antiguedadDeclarada: request.antiguedadDeclarada,
        profesion: request.profesion,
        puestoJerarquico: request.puestoJerarquico,
        rubroEmpleoPublico: request.rubroEmpleoPublico,
      };
      solicitud.titular.situacionLaboral = situacionLaboral;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.codeudorSituacionLaboral))
        cardsChecked = [
          ...state.cardsChecked,
          CARD_IDS.codeudorSituacionLaboral,
        ];
      return {
        ...state,
        cardsChecked,
        isLoading: false,
        solicitud: solicitud,
      };
    },
    [updateCodeudorSituacionLaboralFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    //#endregion

    //#region CONYUGE CODEUDOR-DATOS PERSONALES
    [unCheckConyugeCodeudorDatosPersonales as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.conyugeCodeudorDatosPersonales))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.conyugeCodeudorDatosPersonales
        );

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateConyugeCodeudorDatosPersonalesInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateConyugeCodeudorDatosPersonalesCompleted as any]: (
      state: IState,
      action: IAction<IUpdateDatosPersonalesCompleted>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      solicitud.conyugeCodeudor.datosPersonales.cuil = action.payload.cuil;
      solicitud.conyugeCodeudor.datosPersonales.fechaNacimiento =
        action.payload.fechaNacimiento;
      solicitud.conyugeCodeudor.datosPersonales.tieneLicenciaConducir =
        action.payload.tieneLicenciaConducir;
      solicitud.conyugeCodeudor.datosPersonales.nacionalidad =
        action.payload.nacionalidad;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.conyugeCodeudorDatosPersonales))
        cardsChecked = [
          ...state.cardsChecked,
          CARD_IDS.conyugeCodeudorDatosPersonales,
        ];

      return {
        ...state,
        solicitud: solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateConyugeCodeudorDatosPersonalesFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    //#endregion
    //#region CONYUGE CODEUDOR-DOMICILIO
    [updateConyugeCodeudorDomicilioInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateConyugeCodeudorDomicilioCompleted as any]: (
      state: IState,
      action: IAction<SolicitudDomicilioRequest>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      const { direccion, provincia, localidad, codigoPostal } = action.payload;
      const domicilioPersona: DomicilioPersona = {
        direccion: direccion,
        provincia: provincia,
        localidad: localidad,
        codigoPostal: codigoPostal,
      };
      solicitud.conyugeCodeudor.domicilio = domicilioPersona;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.conyugeCodeudorDomicilio))
        cardsChecked = [
          ...state.cardsChecked,
          CARD_IDS.conyugeCodeudorDomicilio,
        ];

      return {
        ...state,
        solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateConyugeCodeudorDomicilioFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [unCheckConyugeCodeudorDomicilio as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.conyugeCodeudorDomicilio))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.conyugeCodeudorDomicilio
        );

      return {
        ...state,
        cardsChecked,
      };
    },
    //#endregion
    //#region CONYUGE CODEUDOR-SITUACION LABORAL
    [unCheckConyugeCodeudorSituacionLaboral as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.conyugeCodeudorSituacionLaboral))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.conyugeCodeudorSituacionLaboral
        );
      return {
        ...state,
        cardsChecked,
      };
    },
    [updateConyugeCodeudorSituacionLaboralInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [updateConyugeCodeudorSituacionLaboralCompleted as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      const request = action.payload;
      const situacionLaboral: SituacionLaboral = {
        actividad: request.actividad,
        antiguedad: request.antiguedad,
        actividadDeclarada: request.actividadDeclarada,
        ingresosDeclarados: request.ingresosDeclarados,
        antiguedadDeclarada: request.antiguedadDeclarada,
        profesion: request.profesion,
        puestoJerarquico: request.puestoJerarquico,
        rubroEmpleoPublico: request.rubroEmpleoPublico,
      };
      solicitud.titular.situacionLaboral = situacionLaboral;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.conyugeCodeudorSituacionLaboral))
        cardsChecked = [
          ...state.cardsChecked,
          CARD_IDS.conyugeCodeudorSituacionLaboral,
        ];
      return {
        ...state,
        solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateConyugeCodeudorSituacionLaboralFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    //#endregion
    [updateSeguroInited as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [invalidateSeguro as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;

      if (cardsChecked.includes(CARD_IDS.seguro))
        cardsChecked = cardsChecked.filter((x) => x !== CARD_IDS.seguro);

      return {
        ...state,
        cardsChecked,
      };
    },
    [updateSeguroCompleted as any]: (
      state: IState,
      action: IAction<Poliza>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      solicitud.vehiculo.poliza.companiaId = action.payload.companiaId;
      solicitud.vehiculo.poliza.companiaNombre = action.payload.compania;
      solicitud.vehiculo.poliza.cotizacionId = action.payload.cotizacionId;
      solicitud.vehiculo.poliza.cotizacionPlanId =
        action.payload.cotizacionPlanId;
      solicitud.vehiculo.poliza.tipoPolizaNombre = action.payload.nombre;

      solicitud.vehiculo.poliza.cantidadDeCuotas = action.payload.cantCuotas;
      solicitud.vehiculo.poliza.premio = action.payload.importe;
      solicitud.vehiculo.poliza.premioTotal =
        action.payload.cantCuotas * action.payload.importe;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.seguro))
        cardsChecked = [...state.cardsChecked, CARD_IDS.seguro];

      return {
        ...state,
        solicitud,
        isLoading: false,
        cardsChecked,
      };
    },
    [updateSeguroFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    //#region VEHICULO
    [updateVehiculoInited as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [unCheckVehiculo as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.automovil))
        cardsChecked = cardsChecked.filter((x) => x !== CARD_IDS.automovil);
      return {
        ...state,
        cardsChecked,
      };
    },
    [updateVehiculoCompleted as any]: (state: IState, action: IAction<any>) => {
      const request = action.payload;
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      solicitud.vehiculo.datosVehiculo.marca = request.marca;
      solicitud.vehiculo.datosVehiculo.modelo = request.modelo;
      solicitud.vehiculo.datosVehiculo.codia = request.codia;
      solicitud.vehiculo.datosVehiculo.anio = request.anio;
      solicitud.vehiculo.datosVehiculo.valorInfoauto = request.valorInfoauto;
      solicitud.vehiculo.datosVehiculo.valorInfoautoActual =
        request.valorInfoauto;
      solicitud.vehiculo.datosVehiculo.valorDeCompra = request.valorDeCompra;
      solicitud.vehiculo.datosVehiculo.motivoDiferenciaPrecio =
        request.motivoDiferenciaPrecio;
      solicitud.vehiculo.datosVehiculo.color = request.color;
      solicitud.vehiculo.datosVehiculo.nuevo = request.es0Km;
      solicitud.vehiculo.datosVehiculo.usoComercial = request.usoComercial;
      solicitud.vehiculo.datosVehiculo.gnc = request.tieneGNC;
      solicitud.vehiculo.datosVehiculo.usanElAuto = request.usanElAuto;
      solicitud.vehiculo.datosVehiculo.anio = request.anio;
      solicitud.vehiculo.datosVehiculo.anio = request.anio;
      solicitud.vehiculo.datosVehiculo.anio = request.anio;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.automovil))
        cardsChecked = [...state.cardsChecked, CARD_IDS.automovil];

      return {
        ...state,
        solicitud: solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateVehiculoFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    //#endregion

    //#region OPERACION
    [setOperacionPlazo as any]: (state: IState, action: IAction<IListItem>) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      solicitud.operacion.plazo = action.payload.value;
      return {
        ...state,
        solicitud: solicitud,
      };
    },
    [setOperacionPlan as any]: (state: IState, action: IAction<IListItem>) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      solicitud.operacion.planId = action.payload.value;
      solicitud.operacion.nombrePlan = action.payload.label;
      return {
        ...state,
        solicitud: solicitud,
      };
    },
    [calcularPrestamoInited as any]: (state: IState) => {
      return { ...state, isLoading: true };
    },
    [calcularPrestamoCompleted as any]: (
      state: IState,
      action: IAction<OfertaResponse>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      const prestamo = action.payload;
      solicitud.operacion.ltv = prestamo.ltvAplicado;
      solicitud.operacion.rci = prestamo.rciReal;
      solicitud.operacion.tna = prestamo.plan.tna;
      solicitud.operacion.importeNetoAPercibir = prestamo.importeNetoAPercibir;
      return {
        ...state,
        solicitud: solicitud,
        isLoading: false,
      };
    },
    [calcularPrestamoFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    },
    [setCapitalAFinanciar as any]: (state: IState, action: IAction<any>) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      solicitud.operacion.capitalAFinanciar = action.payload;
      return {
        ...state,
        solicitud: solicitud,
      };
    },
    [reformularSolicitudInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return { ...state, isLoading: true };
    },
    [reformularSolicitudCompleted as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.operacion))
        cardsChecked = [...state.cardsChecked, CARD_IDS.operacion];
      return { ...state, cardsChecked, isLoading: false };
    },
    [reformularSolicitudFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return { ...state, error: action.payload, isLoading: false };
    },
    [unCheckOperacion as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.operacion))
        cardsChecked = cardsChecked.filter((x) => x !== CARD_IDS.operacion);
      return {
        ...state,
        cardsChecked,
      };
    },
    [checkOperacion as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.operacion))
        cardsChecked = [...state.cardsChecked, CARD_IDS.operacion];
      return { ...state, cardsChecked };
    },
    //#endregion

    //#region DIFERENCIA NO FINANCIADA
    [unCheckDiferenciaNoFinanciada as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.diferenciaNoFinanciada))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.diferenciaNoFinanciada
        );
      return {
        ...state,
        cardsChecked,
      };
    },
    [updateDiferenciaNoFinanciadaInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return { ...state, isLoading: true };
    },
    [updateDiferenciaNoFinanciadaCompleted as any]: (
      state: IState,
      action: IAction<SolicitudDiferenciaNoFinanciada>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      solicitud.operacion.diferenciaNoFinanciada = action.payload;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.diferenciaNoFinanciada))
        cardsChecked = [...state.cardsChecked, CARD_IDS.diferenciaNoFinanciada];

      return {
        ...state,
        cardsChecked,
        solicitud,
        isLoading: false,
      };
    },
    [updateDiferenciaNoFinanciadaFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return { ...state, error: action.payload, isLoading: false };
    },
    //#endregion
  },
  initialState
);

export default vtSolicitudReducer;

export {
  clear,
  getSolicitud,
  unCheckTitularDatosPersonales,
  updateAndCheckTitularDatosPersonales,
  unCheckTitularDomicilio,
  updateAndCheckTitularDomicilio,
  unCheckTitularSituacionLaboral,
  updateAndCheckTitularSituacionLaboral,
  addTelefono,
  deleteTelefono,
  addRedSocial,
  deleteRedSocial,
  updateAndCheckConyugeDatosPersonales,
  unCheckConyugeDatosPersonales,
  updateAndCheckConyugeDomicilio,
  unCheckConyugeDomicilio,
  updateAndCheckConyugeSituacionLaboral,
  unCheckConyugeSituacionLaboral,
  updateAndCheckCodeudorDatosPersonales,
  unCheckCodeudorDatosPersonales,
  unCheckCodeudorDomicilio,
  updateAndCheckCodeudorDomicilio,
  unCheckCodeudorSituacionLaboral,
  updateAndCheckCodeudorSituacionLaboral,
  unCheckConyugeCodeudorDatosPersonales,
  updateAndCheckConyugeCodeudorDatosPersonales,
  updateAndCheckConyugeCodeudorDomicilio,
  unCheckConyugeCodeudorDomicilio,
  updateAndCheckConyugeCodeudorSituacionLaboral,
  unCheckConyugeCodeudorSituacionLaboral,
  unCheckVehiculo,
  updateAndCheckVehiculo,
  setOperacionPlan,
  setOperacionPlazo,
  calcularPrestamo,
  setCapitalAFinanciar,
  updateSeguro,
  reformularSolicitud,
  unCheckOperacion,
  checkOperacion,
  unCheckDiferenciaNoFinanciada,
  updateAndCheckDiferenciaNoFinanciada,
};
