import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app/App";
import "./assets/cleanup.css";
import "./libs/string.extension";

import { Provider } from "react-redux";
import configureRedux from "./redux/configureRedux";
import environment from "./environment";
import { initAnalytics } from "./libs/amplitudeHelper";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

const initialState = {};
const store = configureRedux(initialState);
initAnalytics(environment.versionDescription);

//TODO: take Auth0 user once implemented
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: environment.launchDarkly.sdkKey
      ? environment.launchDarkly.sdkKey
      : "",
    options: {},
  });
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <LDProvider>
          <App />
        </LDProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();
