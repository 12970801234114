import { createActions, handleActions } from "redux-actions";
import { Reducer } from "redux";
import { RootState } from "redux/reducers";
import { solicitudesLiquidacion } from "api/solicitudesLiquidacion";
import {
  DetallePago,
  EConceptoPago,
  PagosResponse,
  SolicitudPagosRequest,
} from "api/types/originacion/services/liquidacionTypes";

interface IActions {
  liquidacion: {
    pagos: {
      clear: () => void;
      getPagosInited: (props: any) => void;
      getPagosCompleted: (pagos: PagosResponse) => void;
      getPagosFailed: (error: any) => void;
      addPago: (pago: DetallePago) => void;
      removePago: (id: number) => void;
    };
  };
}

const {
  liquidacion: {
    pagos: {
      clear,
      getPagosInited,
      getPagosCompleted,
      getPagosFailed,
      addPago,
      removePago,
    },
  },
}: IActions = createActions({
  liquidacion: {
    pagos: {
      clear: () => ({}),
      getPagosInited: (props: any) => ({ props }),
      getPagosCompleted: (response: PagosResponse) => ({ ...response }),
      getPagosFailed: (error: any) => ({ error }),
      addPago: (response: DetallePago) => ({ ...response }),
      removePago: (position: number) => position,
    },
  },
});

//Pagos
const getPagos = (request: SolicitudPagosRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(getPagosInited(request));
        const response = await solicitudesLiquidacion(accessToken).getPagos(
          request
        );
        dispatch(getPagosCompleted(response));
      }
    } catch (error) {
      console.error(error);
      dispatch(getPagosFailed(error));
    }
  };
};

interface IState {
  error: any | null;
  isLoading: boolean;
  liquidacionAPagar: number;
  liquidacionPendiente: number;
  comisionesAPagar: number;
  comisionesPendiente: number;
  cancelacionAPagar: number;
  cancelacionPendiente: number;
  seguroAPagar: number;
  seguroPendiente: number;
  pagosConfirmados: boolean;
  pagos: DetallePago[];
}

interface IAction<T> {
  payload: T;
}

const initialState: IState = {
  error: null,
  isLoading: true,
  liquidacionAPagar: 0,
  liquidacionPendiente: 0,
  comisionesAPagar: 0,
  comisionesPendiente: 0,
  cancelacionAPagar: 0,
  cancelacionPendiente: 0,
  seguroAPagar: 0,
  seguroPendiente: 0,
  pagosConfirmados: false,
  pagos: [],
};

const pagosReducer: Reducer<IState> = handleActions(
  {
    [clear as any]: () => {
      return {
        ...initialState,
      };
    },
    [getPagosInited as any]: () => {
      return { ...initialState, isLoading: true };
    },
    [getPagosCompleted as any]: (
      state: IState,
      action: IAction<PagosResponse>
    ) => {
      return { ...state, ...action.payload, isLoading: false };
    },
    [getPagosFailed as any]: () => {
      return { ...initialState, isLoading: false };
    },
    [addPago as any]: (state: IState, action: IAction<DetallePago>) => {
      if (action.payload.concepto === EConceptoPago.Liquidacion) {
        state.liquidacionPendiente =
          state.liquidacionPendiente - action.payload.importe;
      } else if (action.payload.concepto === EConceptoPago.Comision) {
        state.comisionesPendiente =
          state.comisionesPendiente - action.payload.importe;
      } else if (action.payload.concepto === EConceptoPago.CancelacionOpAnt) {
        state.cancelacionPendiente =
          state.cancelacionPendiente - action.payload.importe;
      } else if (action.payload.concepto === EConceptoPago.SegurosAdelantados) {
        state.seguroPendiente = state.seguroPendiente - action.payload.importe;
      }
      return {
        ...state,
        pagos: [action.payload, ...state.pagos],
      };
    },
    [removePago as any]: (state: IState, action: IAction<number>) => {
      let removed = state.pagos.splice(action.payload, 1).pop();
      if (removed?.concepto === EConceptoPago.Liquidacion) {
        state.liquidacionPendiente += Number(removed.importe);
      } else if (removed?.concepto === EConceptoPago.Comision) {
        state.comisionesPendiente += Number(removed.importe);
      } else if (removed?.concepto === EConceptoPago.CancelacionOpAnt) {
        state.cancelacionPendiente += Number(removed.importe);
      } else if (removed?.concepto === EConceptoPago.SegurosAdelantados) {
        state.seguroPendiente += Number(removed.importe);
      }
      return {
        ...state,
      };
    },
  },
  initialState
);

export default pagosReducer;

export { clear, getPagos, addPago, getPagosCompleted, removePago };
