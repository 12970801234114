import jsonFetch from "./jsonFetch";
import environment from "environment";
import {
  ConfiguracionImpresion,
  GetPrendaRequest,
  ConfeccionarPrendaRequest,
  Prenda,
  DomicilioDeServicioRequest,
  GetDomicilioDeServicioRequest,
  DomicilioDeServicioResponse
} from "./types/originacion/services/confeccionPrendaTypes";

const confeccionPrenda = (token?: string) => {
  return {
    getPrenda: (props: GetPrendaRequest): Promise<Prenda> => {
      if (!token) throw new Error("Must be authenticated");
      return jsonFetch<Prenda>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/prenda`,
        method: "GET",
      });
    },
    postConfeccionarPrenda: (props: ConfeccionarPrendaRequest): Promise<Prenda> => {
      if (!token) throw new Error("Must be authenticated");
      return jsonFetch<Prenda>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.solicitudId}/prenda`,
        method: "POST",
        jsonBody: props,
      });
    },
    getConfiguracionImpresion: (): Promise<ConfiguracionImpresion[]> => {
      return jsonFetch<ConfiguracionImpresion[]>({
        token,
        method: "GET",
        endpoint: `${environment.originacionUrl}/solicitudes/confeccion/configuracionImpresion`,
      });
    },
    postConfiguracionImpresion: (
      props: ConfiguracionImpresion
    ): Promise<string> => {
      return fetch(
        `${environment.originacionUrl}/solicitudes/confeccion/configuracionImpresion`,
        {
          method: "POST",
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(props),
        }
      )
        .then((response) => response.blob())
        .then((images) => URL.createObjectURL(images))
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getDomicilioServicio: (props: GetDomicilioDeServicioRequest): Promise<DomicilioDeServicioResponse> => {
      return jsonFetch<DomicilioDeServicioRequest>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/domicilios/adicionales`,
        method: "GET",
      });
    },
    
    postDomicilioServicio: (props: DomicilioDeServicioRequest): Promise<DomicilioDeServicioResponse> => {
      return jsonFetch<DomicilioDeServicioRequest>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/domicilios/adicionales`,
        method: "POST",
        jsonBody: props,
      });
    },
  };
};
export { confeccionPrenda };
