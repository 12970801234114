import { ETipoContribuyente } from "../businessTypes";

export interface SolicitudPagosRequest {
  solicitudId?: number;
}

export interface PagosResponse {
  liquidacionAPagar: number;
  liquidacionPendiente: number;
  comisionesAPagar: number;
  comisionesPendiente: number;
  cancelacionAPagar: number;
  cancelacionPendiente: number;
  seguroAPagar: number;
  seguroPendiente: number;
  pagosConfirmados: boolean;
  pagos: DetallePago[];
}

export interface DetallePago {
  bancoDestino: string;
  bancoOrigen: string;
  concepto: EConceptoPago;
  cbuDestino: string;
  cuentaOrigen: string;
  tipo: ETipoPago;
  importe: number;
  nroCheque: string;
  cuitCuentaDestino: string;
}

export enum EConceptoPago {
  Liquidacion = "Liquidacion",
  Comision = "Comision",
  SegurosAdelantados = "SegurosAdelantados",
  CancelacionOpAnt = "CancelacionOpAnt",
}

export enum ETipoPago {
  Efectivo = "Efectivo",
  Cheque = "Cheque",
  Transferencia = "Transferencia",
  Pago_Facil = "Pago Facil",
}

export interface LiquidacionSolicitudRequest {
  id: number;
  empresa: number;
  fechaInscripcionPrenda: string;
  fechaVtoPrimeraCuota: string;
  fechaVtoPrenda: string;
  aval: boolean;
  arancel: boolean;
  provincia: number;
  localidad: string;
  nroRegistro: number;
  fechaLiquidacion: string;
  capitalSolicitado: number;
  gastosOtorgamientoFijos: number;
  gastosOtorgamiento: number;
  ivaGastosOtorgamiento: number;
  impuestoSelladoProvincial: number;
  importeCuotasADescontar: number;
  comisionMaxima: number;
  comisionVendedor: number;
  segurosAdelantados: number;
  cancelacionPrestamosAnteriores: number;
  preprendaCantidadMax: number;
  preprendaImporteMax: number;
  preprendaCantidadUsado: number;
  preprendaImporteUsado: number;
}

export interface InfoParaLiquidarRequest {
  id: number;
}

export interface InfoParaLiquidarResponse {
  id: number;
  empresa: number;
  fechaVtoPrimeraCuota: string;
  capitalSolicitado: number;
  gastosOtorgamientoFijos: number;
  gastosOtorgamiento: number;
  ivaGastosOtorgamiento: number;
  importeCuotasADescontar: number;
  comisionMaxima: number;
  preprendaCantidadMax: number;
  preprendaImporteMax: number;
  preprendaCantidadUsado: number;
  preprendaImporteUsado: number;
}

export interface ImpuestoSelladoProvincialRequest {
  provinciaId: number;
}

export interface ImpuestoSelladoProvincialResponse {
  impuesto: number;
}

export interface AnularLiquidacionRequest {
  solicitudId: number;
  comentario: string;
}

export interface Banco {
  id: number;
  nombre: string;
}

export interface InformarTipoContribuyenteRequest {
  id: number;
  tipoContribuyente: ETipoContribuyente;
}

export interface InformarPagosRequest {
  id: number;
  pagos: DetallePago[];
}

export interface SolicitarSeguroRequest {
  id: number;
}
