import environment from "environment";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

let permissionsDisabled =
  environment.disablePermisology && environment.name === "localhost";

const useTokenPermission = (requiredPermission: string) => {
  const user = useSelector((state: RootState) => {
    return state.authReducer.session?.user;
  });
  if (permissionsDisabled) return true;

  return user?.originacion?.permissions.includes(requiredPermission) ?? false;
};

const useAnyTokenPermissions = (requiredPermissions: string[]) => {
  const user = useSelector((state: RootState) => {
    return state.authReducer.session?.user;
  });
  if (permissionsDisabled) return true;

  return (
    user?.originacion?.permissions.some((permission) =>
      requiredPermissions.includes(permission)
    ) ?? false
  );
};

const useTokenPermissionsSection = (section: string) => {
  const user = useSelector((state: RootState) => {
    return state.authReducer.session?.user;
  });
  if (permissionsDisabled) return true;

  const pattern = `${section}:.*`;
  const regex = new RegExp(pattern);
  return (
    user?.originacion?.permissions.some((permission) =>
      regex.test(permission)
    ) ?? false
  );
};

const useTokenRoles = (requiredRole: string) => {
  const user = useSelector((state: RootState) => {
    return state.authReducer.session?.user;
  });
  if (permissionsDisabled) return true;

  return user?.originacion.roles.includes(requiredRole);
};

export {
  useTokenPermission,
  useAnyTokenPermissions,
  useTokenRoles,
  useTokenPermissionsSection,
};
