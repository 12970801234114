/* eslint-disable no-extend-native */
declare global {
  interface String {
    toTitleCase(): String;
    encodeURI(): String;
  }
}
String.prototype.toTitleCase = function (): string {
  if (!this) return "";
  return this[0].toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.encodeURI = function (): string {
  if (!this) return "";
  return encodeURI(this.toString()).replace("(", "%28").replace(")", "%29");
};
export {};
