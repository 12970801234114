import { createActions, handleActions } from "redux-actions";
import { Reducer } from "redux";
import {
  ImpuestoSelladoProvincialRequest,
  ImpuestoSelladoProvincialResponse,
  InfoParaLiquidarRequest,
  InfoParaLiquidarResponse,
  LiquidacionSolicitudRequest,
} from "api/types/originacion/services/liquidacionTypes";

import { RootState } from "redux/reducers";
import { solicitudesLiquidacion } from "api/solicitudesLiquidacion";
import dateToString from "libs/dateToString";

export const CARD_IDS = {
  infoPrenda: "infoPrenda",
  infoLiquidacion: "infoLiquidacion",
};

interface InfoPrendaRequest {
  isPrenda: boolean;
  conAval: boolean;
  conArancel: boolean;
  fechaInscripcion: string;
  fechaVencimientoPrenda: string;
  provincia: number;
  localidad: string;
  nroRegistro: number;
}
interface InfoLiquidacionRequest {
  vencimientoPrimeraCuota: string;
  gastosOtorgamientoFijos: number;
  ivaGastosOtorgamiento: number;
  comisionVendedor: number;
  segurosAdelantados: number;
  cancelPrestamoAnterior: number;
}

interface IActions {
  confeccion: {
    prenda: {
      clear: () => void;
      getInfoParaLiquidarInited: (props: InfoParaLiquidarRequest) => void;
      getInfoParaLiquidarCompleted: (result: InfoParaLiquidarResponse) => void;
      getInfoParaLiquidarFailed: (error: any) => void;
      //Impuesto sellado
      getImpuestoSelladoInited: (
        props: ImpuestoSelladoProvincialRequest
      ) => void;
      getImpuestoSelladoCompleted: (
        result: ImpuestoSelladoProvincialResponse
      ) => void;
      getImpuestoSelladoFailed: (error: any) => void;
      //InfoPrenda
      unCheckInfoPrenda: () => void;
      updateAndCheckInfoPrenda: (result: InfoPrendaRequest) => void;
      //InfoLiquidacion
      unCheckInfoLiquidacion: () => void;
      updateAndCheckInfoLiquidacion: (result: InfoLiquidacionRequest) => void;
    };
  };
}

const {
  confeccion: {
    prenda: {
      clear,
      getInfoParaLiquidarInited,
      getInfoParaLiquidarCompleted,
      getInfoParaLiquidarFailed,
      //Impuesto sellado
      getImpuestoSelladoInited,
      getImpuestoSelladoCompleted,
      getImpuestoSelladoFailed,
      //InfoPrenda
      unCheckInfoPrenda,
      updateAndCheckInfoPrenda,
      //InfoLiquidacion
      unCheckInfoLiquidacion,
      updateAndCheckInfoLiquidacion,
    },
  },
}: IActions = createActions({
  confeccion: {
    prenda: {
      clear: () => ({}),
      getInfoParaLiquidarInited: (props: any) => ({ props }),
      getInfoParaLiquidarCompleted: (response: InfoParaLiquidarResponse) => ({
        ...response,
      }),
      getInfoParaLiquidarFailed: (error: any) => ({ error }),
      //Impuesto sellado
      getImpuestoSelladoInited: (props: any) => ({ props }),
      getImpuestoSelladoCompleted: (result: InfoPrendaRequest) => ({
        ...result,
      }),
      getImpuestoSelladoFailed: (error: any) => ({ error }),
      //InfoPrenda
      unCheckInfoPrenda: () => ({}),
      updateAndCheckInfoPrenda: (result: InfoPrendaRequest) => ({
        ...result,
      }),

      //InfoLiquidacion
      unCheckInfoLiquidacion: () => ({}),
      updateAndCheckInfoLiquidacion: (result: InfoLiquidacionRequest) => ({
        ...result,
      }),
    },
  },
});

interface IState {
  error: any | null;
  isLoading: boolean;
  liquidacion: LiquidacionSolicitudRequest | undefined;
  cardsChecked: string[];
}
//InfoParaLiquidarRequest
const initialState: IState = {
  error: null,
  isLoading: false,
  liquidacion: undefined,
  cardsChecked: [],
};

interface IAction<T> {
  payload: T;
}

const getInfoParaLiquidarRequest = (request: InfoParaLiquidarRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(getInfoParaLiquidarInited(request));
        const response = await solicitudesLiquidacion(
          accessToken
        ).getInfoParaLiquidar(request);
        dispatch(getInfoParaLiquidarCompleted(response));
      }
    } catch (error) {
      console.error(error);
      dispatch(getInfoParaLiquidarFailed(error));
    }
  };
};
const getImpuestoSellado = (provinciaId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(getImpuestoSelladoInited({ provinciaId }));
        const response = await solicitudesLiquidacion(
          accessToken
        ).getImpuestoSellado({ provinciaId });
        dispatch(getImpuestoSelladoCompleted(response));
      }
    } catch (error) {
      console.error(error);
      dispatch(getImpuestoSelladoFailed(error));
    }
  };
};

const liquidarCreditoReducer: Reducer<IState> = handleActions(
  {
    //#region Solicitud
    [clear as any]: () => {
      return {
        ...initialState,
      };
    },
    [getInfoParaLiquidarInited as any]: () => {
      return { ...initialState, isLoading: true };
    },
    [getInfoParaLiquidarCompleted as any]: (
      state: IState,
      action: IAction<InfoParaLiquidarResponse>
    ) => {
      const liquidacion = {
        id: action.payload.id,
        empresa: action.payload.empresa,
        fechaVtoPrimeraCuota:
          dateToString(action.payload.fechaVtoPrimeraCuota) ??
          action.payload.fechaVtoPrimeraCuota,
        capitalSolicitado: action.payload.capitalSolicitado,
        gastosOtorgamientoFijos: action.payload.gastosOtorgamientoFijos,
        gastosOtorgamiento: action.payload.gastosOtorgamiento,
        ivaGastosOtorgamiento: action.payload.ivaGastosOtorgamiento,
        importeCuotasADescontar: action.payload.importeCuotasADescontar,
        comisionMaxima: action.payload.comisionMaxima,
        preprendaCantidadMax: action.payload.preprendaCantidadMax,
        preprendaImporteMax: action.payload.preprendaImporteMax,
        preprendaCantidadUsado: action.payload.preprendaCantidadUsado,
        preprendaImporteUsado: action.payload.preprendaImporteUsado,
      } as LiquidacionSolicitudRequest;
      return {
        ...initialState,
        isLoading: false,
        liquidacion,
      };
    },
    [getInfoParaLiquidarFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    },
    [getImpuestoSelladoInited as any]: (state: IState) => {
      return { ...state, isLoading: true };
    },
    [getImpuestoSelladoCompleted as any]: (
      state: IState,
      action: IAction<ImpuestoSelladoProvincialResponse>
    ) => {
      const liquidacion = JSON.parse(
        JSON.stringify(state.liquidacion)
      ) as LiquidacionSolicitudRequest;

      liquidacion.impuestoSelladoProvincial =
        (liquidacion?.capitalSolicitado * action.payload.impuesto) / 1000;
      return {
        ...state,
        isLoading: false,
        liquidacion,
      };
    },
    [getImpuestoSelladoFailed as any]: (state: IState) => {
      return { ...state, isLoading: false };
    },
    //#endregion
    //#region DatosPrenda
    [unCheckInfoPrenda as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.infoPrenda))
        cardsChecked = cardsChecked.filter((x) => x !== CARD_IDS.infoPrenda);

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateAndCheckInfoPrenda as any]: (
      state: IState,
      action: IAction<InfoPrendaRequest>
    ) => {
      const liquidacion = JSON.parse(
        JSON.stringify(state.liquidacion)
      ) as LiquidacionSolicitudRequest;
      liquidacion.aval = action.payload.conAval;
      liquidacion.arancel = action.payload.conArancel;
      liquidacion.fechaInscripcionPrenda = action.payload.fechaInscripcion;
      liquidacion.fechaVtoPrenda = action.payload.fechaVencimientoPrenda;
      liquidacion.provincia = action.payload.provincia;
      liquidacion.localidad = action.payload.localidad;
      liquidacion.nroRegistro = action.payload.nroRegistro;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.infoPrenda))
        cardsChecked = [...state.cardsChecked, CARD_IDS.infoPrenda];

      return {
        ...state,
        liquidacion,
        cardsChecked,
      };
    },
    //#endregion
    //#region DatosPrenda
    [unCheckInfoLiquidacion as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.infoLiquidacion))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.infoLiquidacion
        );

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateAndCheckInfoLiquidacion as any]: (
      state: IState,
      action: IAction<InfoLiquidacionRequest>
    ) => {
      const liquidacion = JSON.parse(
        JSON.stringify(state.liquidacion)
      ) as LiquidacionSolicitudRequest;
      liquidacion.cancelacionPrestamosAnteriores =
        action.payload.cancelPrestamoAnterior;
      liquidacion.comisionVendedor = action.payload.comisionVendedor;
      liquidacion.gastosOtorgamientoFijos =
        action.payload.gastosOtorgamientoFijos;
      liquidacion.ivaGastosOtorgamiento = action.payload.ivaGastosOtorgamiento;
      liquidacion.segurosAdelantados = action.payload.segurosAdelantados;
      liquidacion.fechaVtoPrimeraCuota = action.payload.vencimientoPrimeraCuota;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.infoLiquidacion))
        cardsChecked = [...state.cardsChecked, CARD_IDS.infoLiquidacion];

      return {
        ...state,
        liquidacion,
        cardsChecked,
      };
    },
    //#endregion
  },
  initialState
);

export default liquidarCreditoReducer;

export {
  clear,
  getInfoParaLiquidarRequest,
  getImpuestoSellado,
  unCheckInfoPrenda,
  updateAndCheckInfoPrenda,
  unCheckInfoLiquidacion,
  updateAndCheckInfoLiquidacion,
};
