export enum ETipoCredito {
  Prendario = "Prendario",
  Consumo = "Consumo",
  Autoprenda = "Autoprenda",
}

export enum ESolicitudAnalisisResultado {
  Rechazado = "Rechazado",
  Aprobado = "Aprobado",
  AprobadoProvisorio = "AprobadoProvisorio",
  PreAprobado = "PreAprobado",
  InformesPendientes = "InformesPendientes",
  NoValidaIdentidad = "NoValidaIdentidad",
}

export enum ESituacionOperacion {
  Carga_Incompleta = "Carga_Incompleta", //1
  Informes_Pendientes = "Informes_Pendientes", //4
  Rechazada = "Rechazada", //6
  Rechazada_Definitivamente = "Rechazada_Definitivamente", //7
  En_Analisis = "En_Analisis", //92
  No_Apto_Habilitado = "No_Apto_Habilitado", //99
  Aprobada = "Aprobada", //18
  Confeccion_Solicitada = "Confeccion_Solicitada", //80
  Prenda_Confeccionada = "Prenda_Confeccionada", //16
  Liquidacion_solicitada = "Liquidacion_solicitada", //81
  Desistida = "Desistida", //19
  Desistida_por_tiempo = "Desistida_por_tiempo", //20
  Liquidada = "Liquidada", //8
  En_Espera_Informes_Riesgo_Vehiculo = "En_Espera_Informes_Riesgo_Vehiculo", //83
  Con_Vehiculo_Aprobado = "Con_Vehiculo_Aprobado", //84
  Cancelado = "Cancelado", //11
  Cancelado_Anticipadamente = "Cancelado_Anticipadamente", //13
  Cancelado_Anticipadamente_Judicial = "Cancelado_Anticipadamente_Judicial", //25
  Siniestro_en_tramite = "Siniestro_en_tramite", //50
  Siniestro_pendiente_desembolso = "Siniestro_pendiente_desembolso", //51
  Dacion_En_Pago = "Dacion_En_Pago", //59
  Finalizado = "Finalizado", //60
  Plazo_completado = "Plazo_completado", //70
  Para_ofrecer_otras_entidades = "Para_ofrecer_otras_entidades", //61
  Cargada_otras_entidades = "Cargada_otras_entidades", //62
  Rechazada_otras_entidades = "Rechazada_otras_entidades", //63
  Aprobada_otras_entidades = "Aprobada_otras_entidades", //64
  Para_prendar_otras_entidades = "Para_prendar_otras_entidades", //65
  En_espera_liquidacion_otras_entidades = "En_espera_liquidacion_otras_entidades", //66
  Liquidada_otras_entidades = "Liquidada_otras_entidades", //67
  Eliminado = "Eliminado", //12
  Revision_Rechazo_Habilitado = "Revision_Rechazo_Habilitado", //99
}

export enum EEstadoSolicitudConsumo {
  Incompleta = "Incompleta", //1
  En_Analisis = "En_Analisis", //3
  Aprobada = "Aprobada", //4
  Liquidada = "Liquidada", //5
  Rechazada = "Rechazada", //6
  Desistida = "Desistida", //7
}

export enum EEstadoPrenda {
  NoConfeccionada = 0,
}

export enum EEstadoPoliza {
  Pendiente = "Pendiente",
  Solicitada = "Solicitada",
  Emitida = "Emitida",
}

export interface Persona {
  id: number;
  apellido: string;
  nombres: string;
  fechaNacimiento: string;
  tipoDocumento: number;
  nroDocumento: string;
  sexo: ESexo;
  domicilio: string;
  nroDomicilio: string;
  piso: string;
  localidad: string;
  provincia: number;
  codigoPostal: string;
  nacionalidad: number;
  estadoCivil: EEstadoCivil;
  cuil: string;
  tipoPersona: number;
  edadAlAlta: number;
  fechaAlta: string;
  usuarioCreacion: number;
  fechaModificacion: string;
  usuarioModificacion: number;
  bancarizado: boolean;
  operacionId: number;
  tieneLicenciaConducir: boolean;
}

export enum ESexo {
  F = "F",
  M = "M",
}

export enum EEstadoCivil {
  Soltero = 1,
  Casado = 2,
  Divorciado = 3,
  Viudo = 4,
  En_Concubinato = 5,
}

export enum EClasificacionPersona {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
  I = "I",
  J = "J",
}

export enum EUsoVehiculo {
  Particular = "Particular",
  Comercial_No_Definido = "Particular",
  Mercaderia_Herramientas_Fletes = "Mercaderia_Herramientas_Fletes",
  Combustibles = "Combustibles",
  Cargas_Peligrosas = "Cargas_Peligrosas",
  Servicios_Especiales = "Servicios_Especiales",
  Taxi_Remis = "Taxi_Remis",
  Transporte_Publico = "Transporte_Publico",
}

export enum ERubroEmpleoPublico {
  NoDefinido = "NoDefinido",
  FuerzasDeSeguridad = "FuerzasDeSeguridad",
  Docencia = "Docencia",
  Administrativo = "Administrativo",
  FuncionarioPublico = "FuncionarioPublico",
  Salud = "Salud",
}

export enum ETipoActividad {
  No_Definida = "No_Definida",
  Relacion_De_Dependencia_12 = "Relacion_De_Dependencia_12",
  Relacion_De_Dependencia_10 = "Relacion_De_Dependencia_10",
  Relacion_De_Dependencia_7 = "Relacion_De_Dependencia_7",
  Jubilado = "Jubilado",
  Autonomo_Inscripto_2_Anios = "Autonomo_Inscripto_2_Anios",
  Autonomo_No_Inscripto = "Autonomo_No_Inscripto",
  Autonomo_Inscripto_18_Meses = "Autonomo_Inscripto_18_Meses",
}

export enum ETipoActividadDeclarada {
  No_Definida = "No_Definida",
  No_Trabaja = "No_Trabaja",
  Empleado_Publico = "Empleado_Publico",
  Empleado_Privado = "Empleado_Privado",
  Independiente = "Independiente",
  Jubilado = "Jubilado",
}

export enum EPuestoJerarquico {
  SinDefinir = "SinDefinir",
  Gerente = "Gerente",
  Supervisor = "Supervisor",
  Ninguno = "Ninguno",
}
export enum ERedInternet {
  Email = "Email",
  Facebook = "Facebook",
  Instagram = "Instagram",
  Twitter = "Twitter",
}

export enum EHorarioContacto {
  Todo_El_Dia = 0,
  Mañana,
  Tarde,
  Noche,
}

export enum ETipoTelefono {
  Particular = "Particular",
  Laboral = "Laboral",
  Conyuge = "Conyuge",
  Padre = "Padre",
  Hijo = "Hijo",
  Hermano = "Hermano",
  Familiar = "Familiar",
  Amigo = "Amigo",
  Vecino = "Vecino",
  Otro = "Otro",
  Garante = "Garante",
}

export enum EEstadoValidacionTelefono {
  Pendiente_Validacion = "Pendiente_Validacion",
  Validado = "Validado",
  No_Valido = "No_Valido",
}

export enum TipoRelacionPersona {
  Titular = "Titular",
  Conyuge = "Conyuge",
  Codeudor = "Codeudor",
  ConyugeCodeudor = "Conyuge_del_Codeudor",
}

export enum EMotivoDiferenciaPrecio {
  Abolladuras = 1,
  Motor = 2,
  Multas = 3,
  Patentes = 4,
}

export enum EUsanElAuto {
  Titular = "Titular",
  Conyuge = "Conyuge",
  Hijo = "Hijo",
  Hermano = "Hermano",
  Padre = "Padre",
  Empleado = "Empleado",
  Otro = "Otro",
}

export enum EEstadoVT {
  Incompleta = "Incompleta",
  Realizada = "Realizada",
  Infructuosa = "Infructuosa",
}

export enum EMotivoVtInfructuosa {
  NoAtiende = "NoAtiende",
  NrosInvalidos = "NrosInvalidos",
}

export enum ETipoFormularioPrenda {
  Formulario03 = "Formulario03",
  ContratoPrenda = "ContratoPrenda",
  CondicionesGenerales = "CondicionesGenerales",
}

export enum ETipoDomicilio {
  DeServicio = "DeServicio",
}
export enum ETitularServicio {
  Titular = "Titular",
  Conyuge = "Conyuge",
  Familiar = "Familiar",
  Otro = "Otro",
}

export enum ETipoContribuyente {
  ConsumidorFinal = 1,
  IVAInscripto = 2,
  Excento = 3,
}
export interface AcreedorPrendario {
  id: number;
  nombre: string;
}

export interface CanalesConsumo {
  nombre: string;
}
