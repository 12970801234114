import React from "react";
import pxToRem from "libs/pxToRem";
import MuiButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import ButtonCore from "@material-ui/core/Button";
import { Theme, makeStyles, useTheme } from "@material-ui/core";
import styled from "@material-ui/core/styles/styled";

const useStyles = makeStyles((theme) => ({
  group: ({ marginBottom }: any) => ({
    background: "#fff",
    borderRadius: 14,
    border: 0,
    color: "#000",
    height: 112,
    display: "flex",
    marginBottom: marginBottom,
    boxShadow: "0 6px 6px 0 #a28ac933",
    textTransform: "none",
  }),
  buttonBorder: {
    borderRadius: 14,
    justifyContent: "flex-start",
    paddingLeft: 20,
    "&:not(:last-child)": {
      borderRight: 0,
    },
    "&:not(:first-child)::before": {
      content: "' '",
      borderLeft: "1px solid #eee",
      position: "absolute",
      height: "70%",
      left: 0,
      top: "15%",
    },
  },
}));

interface IButtonGroupProps {
  marginBottom?: number;
  children: React.ReactNode;
}
const ButtonGroup: React.FC<IButtonGroupProps> = ({
  children,
  marginBottom,
}) => {
  const classes = useStyles({ marginBottom });

  return (
    <MuiButtonGroup
      variant="text"
      fullWidth
      className={classes.group}
      classes={{ groupedText: classes.buttonBorder }}
    >
      {children}
    </MuiButtonGroup>
  );
};

interface IButtonGroupLabelProps {
  label?: string;
  subLabel?: string;
  icon?: any;
}

const ButtonGroupLabel: React.FC<IButtonGroupLabelProps> = ({
  label,
  subLabel,
  icon,
}) => {
  const theme = useTheme<Theme>();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      <img
        src={icon}
        alt=""
        style={{
          width: 31,
          height: 31,
          marginBottom: 6,
        }}
      />
      <Typography
        variant="caption"
        noWrap
        style={{
          fontWeight: "bold",
          lineHeight: "1.5",
          fontSize: pxToRem(12),
          letterSpacing: pxToRem(0.2),
          textAlign: "left",
          textTransform: "none",
          color: theme.palette.primary.light,
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="caption"
        noWrap
        style={{
          fontWeight: 600,
          lineHeight: "1",
          fontSize: pxToRem(19),
          letterSpacing: pxToRem(0.2),
          textAlign: "left",
          textTransform: "none",
          color: "#616161",
        }}
      >
        {subLabel}
      </Typography>
    </div>
  );
};

const Button = styled(ButtonCore)({
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#fff",
    borderRadius: 14,
  },
});

export { ButtonGroup, ButtonGroupLabel, Button };
