import { createStore, applyMiddleware, Store } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { ApplicationState } from "./appReducer";
import analyticsMiddleware from "../middlewares/analyticsMiddleware";

const configureRedux = (
  initialState: ApplicationState
): Store<ApplicationState> => {
  const composeEnhancers = composeWithDevTools({});

  return createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(thunk, analyticsMiddleware))
  );
};

export default configureRedux;
