import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import authReducer from "components/app/auth/actions";
import vtSolicitudReducer from "components/app/analisis/solicitudAnalisis/verificacionTelefonica/actions";
import reformularSolicitudReducer from "components/app/analisis/solicitudAnalisis/reformular/actions";
import codigoPostalSelectorReducer from "components/business/codigoPostalSelector/actions";
import confeccionPrendaReducer from "components/app/confeccion/confeccionDePrenda/actions";
import liquidarCreditoReducer from "components/app/liquidacion/liquidar/actions";
import pagosReducer from "components/app/liquidacion/pagos/actions";

const rootReducer = combineReducers({
  appReducer,
  authReducer,
  vtSolicitudReducer,
  reformularSolicitudReducer,
  codigoPostalSelectorReducer,
  confeccionPrendaReducer,
  liquidarCreditoReducer,
  pagosReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
