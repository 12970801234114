import React from "react";
import { Link } from "react-router-dom";
import styled from "@material-ui/core/styles/styled";
import pxToRem from "libs/pxToRem";

import MuiList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import MuiListItemText from "@material-ui/core/ListItemText";

import NavbarLayout from "components/styled/layouts/navbarLayout/NavbarLayout";
import TopbarWithTitle from "components/styled/layouts/navbarLayout/TopbarWithTitle";
import ContentSectionMaxWidth from "components/styled/contentSection/ContentSectionMaxWidth";

import { useAuth } from "components/app/auth/useAuth";

import Divider from "@material-ui/core/Divider";
import MuiUsuarioIcon from "@material-ui/icons/AccountCircle";
import MuiActiveIcon from "@material-ui/icons/ChevronRight";
import VersionIcon from "@material-ui/icons/ChangeHistory";
import MuiCerrarSesionIcon from "@material-ui/icons/ExitToApp";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import environment from "environment";
import DevInfo from "components/business/DevInfo";

const socials: { [key: string]: string } = {
  facebook: "Facebook",
  "google-oauth2": "Gmail",
  email: "Email",
  twitter: "Twitter",
  windowslive: "Hotmail",
  yahoo: "Yahoo",
};

const Info: React.FC = () => {
  const { session } = useAuth();

  const socialNetworkId = session?.user?.socialNetworkId
    ? session?.user?.socialNetworkId
    : "";

  const email = session?.user?.email ? session?.user?.email : "";

  return (
    <NavbarLayout
      topbar={() => <TopbarWithTitle title={"Información"} maxWidth />}
    >
      <ContentSectionMaxWidth>
        <List>
          <LightTooltip
            title={email}
            placement={"top"}
            className="tooltipCustom"
          >
            <ListItem>
              <ListItemIcon>
                {!session?.user?.picture && <UsuarioIcon />}
                {session?.user?.picture && (
                  <Avatar
                    alt="perfil"
                    src={session?.user?.picture}
                    style={{ height: 48, width: 48 }}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={session?.user?.preferred_username}
                secondary={
                  socials[socialNetworkId]
                    ? `${socials[socialNetworkId]} | ${email}`
                    : email
                }
              />
            </ListItem>
          </LightTooltip>
          <Divider />
          <Divider />
          <ListItemLink
            to={"/signout"}
            style={{ borderRadius: `0 0 12px 12px` }}
          >
            <ListItemIcon>
              <CerrarSesionIcon />
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión" />
            <ListItemSecondaryAction>
              <ActiveIcon />
            </ListItemSecondaryAction>
          </ListItemLink>
          <Divider />
          <ListItem disabled>
            <ListItemIcon>
              <VersionIcon />
            </ListItemIcon>
            <ListItemText
              primary={`Version ${environment.versionDescription}`}
            />
          </ListItem>
        </List>

        <DevInfo />
      </ContentSectionMaxWidth>
    </NavbarLayout>
  );
};

export default Info;

const ListItemLink = (props: any) => {
  return <ListItem button component={Link} {...props} />;
};

const ListContainer = (props: any) => {
  return <MuiList component={"nav"} {...props} />;
};

const List = styled(ListContainer)(({ theme }) => ({
  width: "100%",
  boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16)",
  borderRadius: pxToRem(12),
  backgroundColor: theme.palette.background.paper,
  padding: 0,
}));

const ListItemText = styled(MuiListItemText)({
  "& .MuiListItemText-primary": {
    fontWeight: 600,
  },
  "& .MuiListItemText-secondary": {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

const ListItemIcon = styled(MuiListItemIcon)({
  minWidth: 0,
  paddingRight: 16,
});

const UsuarioIcon = styled(MuiUsuarioIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 48,
  width: 48,
}));

const CerrarSesionIcon = styled(MuiCerrarSesionIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const ActiveIcon = styled(MuiActiveIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: 3,
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
  "&.tooltipCustom": {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: "bold",
  },
}));
