export interface IMGUser {
  sub: string;
  socialNetworkId: string;
  email: string;
  picture: string;
  preferred_username: string;
  roles: string[];
  perms: string[];
  euroUserId: string;
  isAuthorized: boolean;
  userType: "Agenciero" | "ClienteFinal" | "Productor" | "Interno";
  validatedIdentity: boolean;
  originacion: {
    roles: string[]
    permissions: string[]
  }
}

const getMGUser = (auth0User?: any): IMGUser | null => {
  if (!auth0User) return null;

  const userInfo = auth0User["http://mg-group.com.ar/userInfo"];

  //   const isAuthorized =
  //     userInfo.euroRoles &&
  //     userInfo.euroPermissions &&
  //     userInfo.euroRoles.length > 0 &&
  //     userInfo.euroPermissions.length > 0;

  const isAuthorized = userInfo.email
    .toLowerCase()
    .includes("@mg-group.com.ar");

  return {
    sub: auth0User.sub,
    socialNetworkId: auth0User.sub.split("|")[0],
    email: userInfo.email,
    picture: userInfo.picture,
    preferred_username: userInfo.name,
    roles: userInfo.euroRoles,
    perms: userInfo.euroPermissions,
    euroUserId: userInfo.euroUserId,
    isAuthorized: isAuthorized,
    userType: userInfo.userType ? userInfo.userType : "Agenciero",
    validatedIdentity: userInfo.validatedIdentity,
    originacion: userInfo.originacion
  };
};

export default getMGUser;
