import React from "react";
import styled from "@material-ui/core/styles/styled";

import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import MuiTypography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";

import pxToRem from "libs/pxToRem";

import SecondaryTitle from "components/styled/texts/SecondaryTitle";
import ContentSection from "components/styled/contentSection/ContentSection";
import ContentSectionMaxWidth from "components/styled/contentSection/ContentSectionMaxWidth";

const NAV_BAR_HEIGHT = 44;

const TopbarWithTitle: React.FC<{ title: string; maxWidth?: boolean }> = ({
  title,
  maxWidth,
}) => {
  const Section = maxWidth ? ContentSectionMaxWidth : ContentSection;

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <>
      <Fade timeout={500} appear={false} in={isScrolled}>
        <ScrolledTopBarContainer scrolled={isScrolled.toString()}>
          <TopBarContent>
            <Typography variant={"h1"}>{title}</Typography>
          </TopBarContent>
        </ScrolledTopBarContainer>
      </Fade>
      <TopBarContainer>
        <Section>
          <SecondaryTitle label={title} />
        </Section>
      </TopBarContainer>
    </>
  );
};

export default TopbarWithTitle;

/// this is a string because is view as a html atribute
interface IScrolled {
  scrolled?: string;
}

const ScrolledTopBarContainer = styled("div")(({ scrolled }: IScrolled) => ({
  position: "sticky",
  top: 0,
  background: scrolled ? "#663ea8" : "transparent",
  boxShadow: scrolled
    ? "0 2px 3px 0 #00000029"
    : "0 -1px 3px 0 rgba(0, 0, 0, 0.1)",
  zIndex: 999,
  height: NAV_BAR_HEIGHT,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const TopBarContent = styled("div")({
  flex: 1,
  padding: 0,
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
});

const TopBarContainer = styled("div")({
  padding: pxToRem(16),
  paddingTop: 0,
  paddingBottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
});

const Typography = styled(MuiTypography)({
  fontSize: pxToRem(18),
  fontWeight: "bold",
  lineHeight: "44px",
  margin: 0,
  color: "#fff",
});
