import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, renewSession } from "./actions";
import { RootState } from "redux/reducers";
import { configuracion } from "api/configuracion";
import useToken from "components/hooks/useToken";

const isSessionAlive = (expiresAt: number): boolean => {
  return new Date().getTime() < expiresAt;
};

const useReduxSelector = () =>
  useSelector((state: RootState) => {
    const authReducer = state.authReducer;

    return {
      session: authReducer.session,
      isLoading: authReducer.isLoading,
      isAlreadyLoggedIn: authReducer.isAlreadyLoggedIn,
    };
  });

export const useRefreshSession = () => {
  const { session, isAlreadyLoggedIn } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    let timerForWebRefresh: NodeJS.Timeout | null = null;

    const isAuthenticated = session && isSessionAlive(session.expiresAt);

    if (isAuthenticated || isAlreadyLoggedIn) {
      const TWO_MINUTES = 2 * 60 * 1000;
      let timeToExpire = 0;
      if (session && session.expiresAt > 0) {
        timeToExpire = session.expiresAt - new Date().getTime();
        timeToExpire =
          timeToExpire > TWO_MINUTES ? timeToExpire - TWO_MINUTES : 0;
      }

      timerForWebRefresh = setTimeout(() => {
        dispatch(renewSession());
      }, timeToExpire);
    }
    return function cleanup() {
      if (timerForWebRefresh) clearTimeout(timerForWebRefresh);
    };
  }, [session, isAlreadyLoggedIn, dispatch]);
};

const useAuth0UserValidation = () => {
  const { session } = useReduxSelector();
  const dispatch = useDispatch();
  const token = useToken();

  useEffect(() => {
    if (token && session && session.user && !session.user.euroUserId) {
      setTimeout(() => {
        dispatch(logout());
      }, 500);
    }
  }, [session, token, dispatch]);
};

export const useAuth = () => {
  const { session, isLoading, isAlreadyLoggedIn } = useReduxSelector();

  const isAuthenticated = session && isSessionAlive(session.expiresAt);
  useAuth0UserValidation();

  return {
    session,
    isLoading,
    isAuthenticated,
    isAlreadyLoggedIn,
  };
};
