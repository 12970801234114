import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import Divider from "@material-ui/core/Divider";
import pxToRem from "libs/pxToRem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";

import Switch from "@material-ui/core/Switch";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: pxToRem(12),
    backgroundColor: theme.palette.background.paper,
  },
  bold: {
    fontWeight: 600,
  },
}));

const DevInfo = () => {
  const { betaMode } = useFlags();

  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <ListSubheader>DEV INFO</ListSubheader>
      <List className={classes.root} component="nav" style={{ padding: 0 }}>
        <ListItem>
          <ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
            <BuildRoundedIcon
              style={{
                color: theme.palette.primary.main,
              }}
            />
          </ListItemIcon>
          <ListItemText
            id="isBetaMode"
            classes={{ primary: classes.bold }}
            primary="Is Beta Mode"
          />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
              checked={betaMode ? true : false}
              inputProps={{ "aria-labelledby": "isBetaMode" }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

export default DevInfo;
