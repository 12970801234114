const convertToQueryString = (params: any): string =>
  Object.keys(params)
    .filter(
      (key) => typeof params[key] !== 'undefined'
    )
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

export default convertToQueryString;
