import React from "react";
import styled from "@material-ui/styles/styled";

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const ContentSection = ({ children, style }: IProps) => {
  return <Section style={{ ...style }}>{children}</Section>;
};

export default ContentSection;

const Section = styled("section")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});
