import jsonFetch from "./jsonFetch";
import environment from "environment";
import convertToQueryString from "./convertToQueryString";

import {
  LocalidadesRequest,
  CodigoPostalRequest,
  ModelosInfoautoResponse,
  ModeloInfoautoResponse,
  RegistrosAutomotoresRequest,
  RegistrosAutomotoresResponse,
  ModeloInfoautoPrecioResponse,
  ModelosInfoautosRequest,
} from "./types/originacion/services/analisisSolicitudTypes";

import { IListItem } from "api/types/originacion/commonTypes";

const direcciones = (token: string) => {
  return {
    getLocalidades: (props: LocalidadesRequest): Promise<IListItem[]> => {
      return jsonFetch<string[]>({
        token,
        endpoint: `${environment.originacionUrl}/provincias/${props.id}/localidades`,
        method: "GET",
      }).then((localidadesList: string[]): IListItem[] => {
        return localidadesList.map(
          (e: string) => ({ value: e, label: e } as IListItem)
        );
      });
    },
    getCodigosPostales: (props: CodigoPostalRequest): Promise<IListItem[]> => {
      const params = props
        ? `?${convertToQueryString({ localidad: props.localidad })}`
        : "";

      return jsonFetch<string[]>({
        token,
        endpoint: `${environment.originacionUrl}/provincias/${props.id}/codigospostales/${params}`,
        method: "GET",
      }).then((codigosPostalesList: string[]): IListItem[] => {
        return codigosPostalesList.map(
          (e: string) => ({ value: e, label: e } as IListItem)
        );
      });
    },
    getRegistrosAutomotores: (
      props: RegistrosAutomotoresRequest
    ): Promise<IListItem[]> => {
      const params = props ? `?${convertToQueryString(props)}` : "";
      return jsonFetch<RegistrosAutomotoresResponse[]>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/liquidacion/registrosAutomotor/${params}`,
        method: "GET",
      }).then((registrosList: RegistrosAutomotoresResponse[]): IListItem[] => {
        return registrosList.map(
          (reg: RegistrosAutomotoresResponse) =>
            ({ value: reg.id, label: reg.denominacion } as IListItem)
        );
      });
    },
  };
};

const infoautos = (token: string) => {
  return {
    getMarcas: (): Promise<IListItem[]> => {
      return jsonFetch<string[]>({
        token,
        endpoint: `${environment.vehiclesUrl}/marcas`,
        method: "GET",
      }).then((marcas: string[]): IListItem[] =>
        marcas.map((e: string): IListItem => ({ value: e, label: e }))
      );
    },
    getVersiones: (props: ModelosInfoautosRequest): Promise<IListItem[]> => {
      return jsonFetch<ModelosInfoautoResponse>({
        token,
        endpoint: `${environment.vehiclesUrl}/marcas/${encodeURI(
          props.marca
        )}/anios/${encodeURI(props.anio.toString())}/versiones`,
        method: "GET",
      }).then((modelosList: ModelosInfoautoResponse): IListItem[] => {
        return modelosList.items.map(
          (e: ModeloInfoautoResponse): IListItem => ({
            value: e.codia,
            label: e.version,
          })
        );
      });
    },
    getVersionDetalle: (codia: string): Promise<ModeloInfoautoResponse> => {
      return jsonFetch<ModeloInfoautoResponse>({
        token,
        endpoint: `${environment.vehiclesUrl}/versiones/${encodeURI(codia)}`,
        method: "GET",
      });
    },
    getVersionPrecio: (
      codia: string
    ): Promise<ModeloInfoautoPrecioResponse[]> => {
      return jsonFetch<ModeloInfoautoPrecioResponse[]>({
        token,
        endpoint: `${environment.vehiclesUrl}/versiones/${encodeURI(
          codia
        )}/precios`,
        method: "GET",
      }).then((data) => data.sort((a, b) => b.precio - a.precio));
    },
  };
};

export { direcciones, infoautos };
