const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

interface IFetchProps<TRequest> {
  token?: string;
  endpoint: string;
  method?: string;
  jsonBody?: TRequest;
  headers?: any;
}

const jsonFetch = <TResponse, TRequest = any>({
  token,
  endpoint,
  method = "GET",
  jsonBody,
  headers = DEFAULT_HEADERS,
}: IFetchProps<TRequest>): Promise<TResponse> => {
  let config = {
    method,
    body: jsonBody ? JSON.stringify(jsonBody) : null,
    headers: {
      ...headers,
    },
  };
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return new Promise((resolve, reject) =>
    fetch(endpoint, config)
      .then(async (response) => {
        const json: any = isJsonResponse(response)
          ? await response.json()
          : response;
        if (response.ok) {
          resolve(json);
          return;
        }

        const error = { status: response.status, ...json };
        console.error({ endpoint, method }, error);
        reject(error);

        return;
      })
      .catch((error) => {
        console.error({ endpoint, method }, error);
        reject(error);

        return;
      })
  );
};

const isJsonResponse = (response: any) => {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
};

export default jsonFetch;
