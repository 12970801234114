import { createActions, handleActions } from "redux-actions";
import { Reducer } from "redux";
import { RootState } from "redux/reducers";
import { solicitudes } from "api/solicitudes";
import {
  SolicitudAnalisisRequest,
  SolicitudAnalisisResponse,
  SolicitudCalculoPrestamoRequest,
  OfertaResponse,
  SolicitudVehiculoRequest,
  ReformularSolicitudRequest,
  SolicitudDiferenciaNoFinanciada,
} from "api/types/originacion/services/analisisSolicitudTypes";

import { IListItem } from "api/types/originacion/commonTypes";
import dateToString from "libs/dateToString";

import { Cotizacion } from "api/types/originacion/services/segurosTypes";
import { solicitudesSeguros } from "api/solicitudesSeguros";

export const CARD_IDS = {
  automovil: "automovil",
  seguro: "seguro",
  operacion: "operacion/datosCredito",
  diferenciaNoFinanciada: "operacion/diferenciaNoFinanciada",
};

interface IActions {
  analisis: {
    reformular: {
      clear: () => void;
      //
      getSolicitudInited: (props: any) => void;
      getSolicitudCompleted: (response: SolicitudAnalisisResponse) => void;
      getSolicitudFailed: (error: any) => void;

      //Vehiculo
      unCheckVehiculo: () => void;
      updateVehiculoInited: (props: any) => void;
      updateVehiculoCompleted: (request: SolicitudVehiculoRequest) => void;
      updateVehiculoFailed: (error: any) => void;

      //seguros
      updateSeguroInited: (props: any) => void;
      updateSeguroCompleted: (poliza: Poliza) => void;
      updateSeguroFailed: (error: any) => void;
      invalidateSeguro: () => void;

      //Operacion
      setOperacionPlan: (value: IListItem) => void;
      setOperacionPlazo: (value: IListItem) => void;
      calcularPrestamoInited: (props: any) => void;
      calcularPrestamoCompleted: (response: OfertaResponse) => void;
      calcularPrestamoFailed: (error: any) => void;
      setCapitalAFinanciar: (value: number) => void;
      reformularSolicitudInited: (props: any) => void;
      reformularSolicitudCompleted: () => void;
      reformularSolicitudFailed: (error: any) => void;
      unCheckOperacion: () => void;
      checkOperacion: () => void;

      //DiferenciaNoFinanciada
      unCheckDiferenciaNoFinanciada: () => void;
      updateDiferenciaNoFinanciadaInited: (props: any) => void;
      updateDiferenciaNoFinanciadaCompleted: (
        request: SolicitudDiferenciaNoFinanciada
      ) => void;
      updateDiferenciaNoFinanciadaFailed: (error: any) => void;
    };
  };
}

const {
  analisis: {
    reformular: {
      clear,
      //
      getSolicitudInited,
      getSolicitudCompleted,
      getSolicitudFailed,

      //Vehiculo, auto
      unCheckVehiculo,
      updateVehiculoInited,
      updateVehiculoCompleted,
      updateVehiculoFailed,
      //Operacion
      setOperacionPlan,
      setOperacionPlazo,
      calcularPrestamoInited,
      calcularPrestamoCompleted,
      calcularPrestamoFailed,
      setCapitalAFinanciar,
      reformularSolicitudInited,
      reformularSolicitudCompleted,
      reformularSolicitudFailed,
      unCheckOperacion,
      checkOperacion,
      //Diferencia no financiada
      unCheckDiferenciaNoFinanciada,
      updateDiferenciaNoFinanciadaInited,
      updateDiferenciaNoFinanciadaCompleted,
      updateDiferenciaNoFinanciadaFailed,

      //seguros
      updateSeguroInited,
      updateSeguroCompleted,
      updateSeguroFailed,
      invalidateSeguro,
    },
  },
}: IActions = createActions({
  analisis: {
    reformular: {
      clear: () => ({}),
      //
      getSolicitudInited: (props: any) => ({ props }),
      getSolicitudCompleted: (response: SolicitudAnalisisResponse) => ({
        ...response,
      }),
      getSolicitudFailed: (error: any) => ({ error }),
      //Vehiculo
      unCheckVehiculo: () => ({}),
      updateVehiculoInited: (props: any) => ({ props }),
      updateVehiculoCompleted: (request: SolicitudVehiculoRequest) => ({
        ...request,
      }),
      updateVehiculoFailed: (error: any) => ({ error }),
      //seguros
      updateSeguroInited: (props: any) => ({ props }),
      updateSeguroCompleted: (poliza: Poliza) => poliza,
      updateSeguroFailed: (error: any) => ({ error }),
      invalidateSeguro: () => ({}),
      //Operacion
      setOperacionPlan: (value: IListItem) => ({ ...value }),
      setOperacionPlazo: (value: IListItem) => ({ ...value }),
      calcularPrestamoInited: (props: any) => ({ props }),
      calcularPrestamoCompleted: (response: OfertaResponse) => ({
        ...response,
      }),
      calcularPrestamoFailed: (error: any) => ({ error }),
      setCapitalAFinanciar: (value: number) => value,
      reformularSolicitudInited: (props: any) => ({ props }),
      reformularSolicitudCompleted: () => ({}),
      reformularSolicitudFailed: (error: any) => ({ error }),
      unCheckOperacion: () => ({}),
      checkOperacion: () => ({}),
      //Diferencia no Financiada
      unCheckDiferenciaNoFinanciada: () => ({}),
      updateDiferenciaNoFinanciadaInited: (props: any) => ({ props }),
      updateDiferenciaNoFinanciadaCompleted: (
        request: SolicitudDiferenciaNoFinanciada
      ) => ({ request: { ...request } }),
      updateDiferenciaNoFinanciadaFailed: (error: any) => ({ error }),
    },
  },
});

const getSolicitud = (request: SolicitudAnalisisRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(getSolicitudInited(request));
        const response = await solicitudes(accessToken).getSolicitud(request);
        dispatch(getSolicitudCompleted(response));
      }
    } catch (error) {
      console.error(error);
      dispatch(getSolicitudFailed(error));
    }
  };
};

interface Poliza extends Cotizacion {
  cotizacionId: string;
  companiaId: number;
  compania: string;
}

const updateSeguro = (solicitudId: number, poliza: Poliza) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateSeguroInited({ ...poliza, solicitudId }));
        await solicitudesSeguros(accessToken).postCotizacion({
          id: solicitudId,
          cotizacionId: poliza.cotizacionId,
          cotizacionPlanId: poliza.cotizacionPlanId,
          tipoPolizaId: poliza.tipoPolizaId,
          pendiente: false,
        });
        dispatch(updateSeguroCompleted(poliza));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateSeguroFailed(error));
    }
  };
};

const calcularPrestamo = (request: SolicitudCalculoPrestamoRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(calcularPrestamoInited(request));

        const response = await solicitudes(accessToken).getCalculoPrestamo(
          request
        );
        dispatch(calcularPrestamoCompleted(response));
      }
    } catch (error) {
      console.error(error);
      dispatch(calcularPrestamoFailed(error));
    }
  };
};

const updateAndCheckVehiculo = (request: SolicitudVehiculoRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateVehiculoInited({ request }));

        const solicitud = getState().reformularSolicitudReducer.solicitud;

        const haCambiadoElVehiculo =
          solicitud?.vehiculo.datosVehiculo.codia !== request.codia ||
          solicitud?.vehiculo.datosVehiculo.anio !== request.anio ||
          solicitud?.vehiculo.datosVehiculo.usoComercial !==
            request.usoComercial;

        if (haCambiadoElVehiculo) {
          dispatch(invalidateSeguro());
        }

        await solicitudes(accessToken).postActualizarVehiculo(request);

        dispatch(updateVehiculoCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateVehiculoFailed(error));
    }
  };
};

//OPERACION
const reformularSolicitud = (request: ReformularSolicitudRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(reformularSolicitudInited(request));
        await solicitudes(accessToken).postReformularSolicitud(request);
        dispatch(reformularSolicitudCompleted());
      }
    } catch (error) {
      console.error(error);
      dispatch(reformularSolicitudFailed(error));
    }
  };
};

//DIFERENCIA NO FINANCIADA
const updateAndCheckDiferenciaNoFinanciada = (
  request: SolicitudDiferenciaNoFinanciada
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(updateDiferenciaNoFinanciadaInited(request));
        await solicitudes(accessToken).postDiferenciaNoFinanciada({
          id: request.id,
          efectivo: request.efectivo,
          vendeVehiculo: request.vendeVehiculo,
          vehiculoVendidoCodia: request.vehiculoVendidoCodia,
          vehiculoVendidoAnio: request.vehiculoVendidoAnio,
          otraDiferencia: request.otraDiferencia,
        });
        dispatch(updateDiferenciaNoFinanciadaCompleted(request));
      }
    } catch (error) {
      console.error(error);
      dispatch(updateDiferenciaNoFinanciadaFailed(error));
    }
  };
};

interface IFieldsFlag {
  [fieldName: string]: boolean;
}

interface IState {
  error: any | null;
  isLoading: boolean;
  solicitud: SolicitudAnalisisResponse | null;
  cardsChecked: string[];
  vehiculoAutoEditing: IFieldsFlag;
  vehiculoAutoChecked: IFieldsFlag;
}

const initialState: IState = {
  error: null,
  isLoading: false,
  solicitud: null,
  cardsChecked: [CARD_IDS.seguro],
  vehiculoAutoEditing: {},
  vehiculoAutoChecked: {},
};

interface IAction<T> {
  payload: T;
}

const reformularSolicitudReducer: Reducer<IState> = handleActions(
  {
    //#region Solicitud
    [clear as any]: () => {
      return {
        ...initialState,
      };
    },
    [getSolicitudInited as any]: () => {
      return { ...initialState, isLoading: true };
    },
    [getSolicitudCompleted as any]: (
      state: IState,
      action: IAction<SolicitudAnalisisResponse>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(action.payload)
      ) as SolicitudAnalisisResponse;
      solicitud.titular.datosPersonales.fechaNacimiento =
        dateToString(solicitud.titular.datosPersonales.fechaNacimiento) ??
        solicitud.titular.datosPersonales.fechaNacimiento;

      return {
        ...initialState,
        isLoading: false,
        solicitud: solicitud,
      };
    },
    [getSolicitudFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    },
    //#endregion
    [updateSeguroInited as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [invalidateSeguro as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.seguro))
        cardsChecked = cardsChecked.filter((x) => x !== CARD_IDS.seguro);

      return {
        ...state,
        cardsChecked,
      };
    },
    [updateSeguroCompleted as any]: (
      state: IState,
      action: IAction<Poliza>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      solicitud.vehiculo.poliza.companiaId = action.payload.companiaId;
      solicitud.vehiculo.poliza.companiaNombre = action.payload.compania;
      solicitud.vehiculo.poliza.cotizacionId = action.payload.cotizacionId;
      solicitud.vehiculo.poliza.cotizacionPlanId =
        action.payload.cotizacionPlanId;
      solicitud.vehiculo.poliza.tipoPolizaNombre = action.payload.nombre;

      solicitud.vehiculo.poliza.cantidadDeCuotas = action.payload.cantCuotas;
      solicitud.vehiculo.poliza.premio = action.payload.importe;
      solicitud.vehiculo.poliza.premioTotal =
        action.payload.cantCuotas * action.payload.importe;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.seguro))
        cardsChecked = [...state.cardsChecked, CARD_IDS.seguro];

      return {
        ...state,
        solicitud,
        isLoading: false,
        cardsChecked,
      };
    },
    [updateSeguroFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    //#region VEHICULO
    [updateVehiculoInited as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [unCheckVehiculo as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.automovil))
        cardsChecked = cardsChecked.filter((x) => x !== CARD_IDS.automovil);
      return {
        ...state,
        cardsChecked,
      };
    },
    [updateVehiculoCompleted as any]: (state: IState, action: IAction<any>) => {
      const request = action.payload;
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      solicitud.vehiculo.datosVehiculo.marca = request.marca;
      solicitud.vehiculo.datosVehiculo.modelo = request.modelo;
      solicitud.vehiculo.datosVehiculo.codia = request.codia;
      solicitud.vehiculo.datosVehiculo.anio = request.anio;
      solicitud.vehiculo.datosVehiculo.valorInfoauto = request.valorInfoauto;
      solicitud.vehiculo.datosVehiculo.valorInfoautoActual =
        request.valorInfoauto;
      solicitud.vehiculo.datosVehiculo.valorDeCompra = request.valorDeCompra;
      solicitud.vehiculo.datosVehiculo.motivoDiferenciaPrecio =
        request.motivoDiferenciaPrecio;
      solicitud.vehiculo.datosVehiculo.color = request.color;
      solicitud.vehiculo.datosVehiculo.nuevo = request.es0Km;
      solicitud.vehiculo.datosVehiculo.usoComercial = request.usoComercial;
      solicitud.vehiculo.datosVehiculo.gnc = request.tieneGNC;
      solicitud.vehiculo.datosVehiculo.usanElAuto = request.usanElAuto;
      solicitud.vehiculo.datosVehiculo.anio = request.anio;
      solicitud.vehiculo.datosVehiculo.anio = request.anio;
      solicitud.vehiculo.datosVehiculo.anio = request.anio;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.automovil))
        cardsChecked = [...state.cardsChecked, CARD_IDS.automovil];

      return {
        ...state,
        solicitud: solicitud,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateVehiculoFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    //#endregion

    //#region OPERACION
    [setOperacionPlazo as any]: (state: IState, action: IAction<IListItem>) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      solicitud.operacion.plazo = action.payload.value;
      return {
        ...state,
        solicitud: solicitud,
      };
    },
    [setOperacionPlan as any]: (state: IState, action: IAction<IListItem>) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      solicitud.operacion.planId = action.payload.value;
      solicitud.operacion.nombrePlan = action.payload.label;
      return {
        ...state,
        solicitud: solicitud,
      };
    },
    [calcularPrestamoInited as any]: (state: IState) => {
      return { ...state, isLoading: true };
    },
    [calcularPrestamoCompleted as any]: (
      state: IState,
      action: IAction<OfertaResponse>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      const prestamo = action.payload;
      solicitud.operacion.ltv = prestamo.ltvAplicado;
      solicitud.operacion.rci = prestamo.rciReal;
      solicitud.operacion.tna = prestamo.plan.tna;
      solicitud.operacion.importeNetoAPercibir = prestamo.importeNetoAPercibir;
      return {
        ...state,
        solicitud: solicitud,
        isLoading: false,
      };
    },
    [calcularPrestamoFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    },
    [setCapitalAFinanciar as any]: (state: IState, action: IAction<any>) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;
      solicitud.operacion.capitalAFinanciar = action.payload;
      return {
        ...state,
        solicitud: solicitud,
      };
    },
    [reformularSolicitudInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return { ...state, isLoading: true };
    },
    [reformularSolicitudCompleted as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.operacion))
        cardsChecked = [...state.cardsChecked, CARD_IDS.operacion];
      return { ...state, cardsChecked, isLoading: false };
    },
    [reformularSolicitudFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return { ...state, error: action.payload, isLoading: false };
    },
    [unCheckOperacion as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.operacion))
        cardsChecked = cardsChecked.filter((x) => x !== CARD_IDS.operacion);
      return {
        ...state,
        cardsChecked,
      };
    },
    [checkOperacion as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.operacion))
        cardsChecked = [...state.cardsChecked, CARD_IDS.operacion];
      return { ...state, cardsChecked };
    },
    //#endregion

    //#region DIFERENCIA NO FINANCIADA
    [unCheckDiferenciaNoFinanciada as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.diferenciaNoFinanciada))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.diferenciaNoFinanciada
        );
      return {
        ...state,
        cardsChecked,
      };
    },
    [updateDiferenciaNoFinanciadaInited as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return { ...state, isLoading: true };
    },
    [updateDiferenciaNoFinanciadaCompleted as any]: (
      state: IState,
      action: IAction<SolicitudDiferenciaNoFinanciada>
    ) => {
      let solicitud = JSON.parse(
        JSON.stringify(state.solicitud)
      ) as SolicitudAnalisisResponse;

      solicitud.operacion.diferenciaNoFinanciada = action.payload;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.diferenciaNoFinanciada))
        cardsChecked = [...state.cardsChecked, CARD_IDS.diferenciaNoFinanciada];

      return {
        ...state,
        cardsChecked,
        solicitud,
        isLoading: false,
      };
    },
    [updateDiferenciaNoFinanciadaFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return { ...state, error: action.payload, isLoading: false };
    },
    //#endregion
  },
  initialState
);

export default reformularSolicitudReducer;

export {
  clear,
  getSolicitud,
  unCheckVehiculo,
  updateAndCheckVehiculo,
  setOperacionPlan,
  setOperacionPlazo,
  calcularPrestamo,
  setCapitalAFinanciar,
  updateSeguro,
  reformularSolicitud,
  unCheckOperacion,
  checkOperacion,
  unCheckDiferenciaNoFinanciada,
  updateAndCheckDiferenciaNoFinanciada,
};
