import { createActions, handleActions } from "redux-actions";
import { Reducer } from "redux";
import { RootState } from "redux/reducers";
import { direcciones } from "api/catalogs";
import { CodigoPostalRequest } from "api/types/originacion/services/analisisSolicitudTypes";
import { IListItem } from "api/types/originacion/commonTypes";

interface IActions {
  business: {
    codigosPostales: {
      getCodigosPostalesInited: (props: any) => void;
      getCodigosPostalesCompleted: (response: IListItem[]) => void;
      getCodigosPostalesFailed: (error: any) => void;
    };
  };
}

const {
  business: {
    codigosPostales: {
      getCodigosPostalesInited,
      getCodigosPostalesCompleted,
      getCodigosPostalesFailed,
    },
  },
}: IActions = createActions({
  business: {
    codigosPostales: {
      getCodigosPostalesInited: (props: any) => ({ props }),
      getCodigosPostalesCompleted: (response: IListItem[]) => [...response],
      getCodigosPostalesFailed: (error: any) => ({ error }),
    },
  },
});

const getCodigosPostales = (request: CodigoPostalRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(getCodigosPostalesInited(request));
        const response = await direcciones(accessToken).getCodigosPostales(
          request
        );
        dispatch(getCodigosPostalesCompleted(response));
      }
    } catch (error) {
      console.error(error);
      dispatch(getCodigosPostalesFailed(error));
    }
  };
};

interface IState {
  error: any | null;
  isLoading: boolean;
  codigosPostales: IListItem[] | null;
}

const initialState: IState = {
  error: null,
  isLoading: false,
  codigosPostales: null,
};

interface IAction<T> {
  payload: T;
}

const codigoPostalSelectorReducer: Reducer<IState> = handleActions(
  {
    [getCodigosPostalesInited as any]: () => {
      return { ...initialState, isLoading: true };
    },
    [getCodigosPostalesCompleted as any]: (
      state: IState,
      action: IAction<IListItem[]>
    ) => {
      return {
        ...initialState,
        isLoading: false,
        codigosPostales: action.payload,
      };
    },
    [getCodigosPostalesFailed as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    },
  },
  initialState
);

export default codigoPostalSelectorReducer;
export { getCodigosPostales };
