import { format, isValid, parse } from "date-fns";
import { es } from "date-fns/esm/locale";

const dateToString = (date: any, formatOption: string = "dd/MM/yyyy") => {
  if (!date) return undefined;

  try {
    return format(new Date(date), formatOption, {
      locale: es,
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const dateIsValid = (date: any, formatOption: string = "dd/MM/yyyy") => {
  if (!date) return false;

  try {
    return isValid(parse(date, formatOption, new Date(), { locale: es }));
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const convertStringToDate = (dateString: string) => {
  const d = dateString.split("/");

  return new Date(d[2] + "/" + d[1] + "/" + d[0]);
};

export default dateToString;
