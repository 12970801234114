import { createActions, handleActions } from "redux-actions";
import { Reducer } from "redux";
import { RootState } from "redux/reducers";
import { confeccionPrenda } from "api/confeccionPrenda";

import { SolicitudAnalisisResponse } from "api/types/originacion/services/analisisSolicitudTypes";

import {
  DomicilioLegal,
  GetPrendaRequest,
  Prenda,
  VehiculoPrenda,
} from "api/types/originacion/services/confeccionPrendaTypes";

import dateToString from "libs/dateToString";

export const CARD_IDS = {
  datosPrenda: "datosPrenda",
  titularDomicilio: "titular/domicilio",
  conyugeDomicilio: "conyuge/domicilio",
  codeudorDomicilio: "codeudor/domicilio",
  conyugeCodeudorDomicilio: "conyugeCodeudor/domicilio",
  vehiculo: "vehiculo",
};

interface DatosPrendaRequest {
  nroFormulario: number;
  lugarInscripcion: string;
  primerVencimiento: string;
  conyugeCotitular: boolean;
  codeudorCotitular: boolean;
}

interface IActions {
  confeccion: {
    prenda: {
      clear: () => void;
      //
      getPrendaInited: (props: any) => void;
      getPrendaCompleted: (response: Prenda) => void;
      getPrendaFailed: (error: any) => void;
      //DatosPrenda
      unCheckDatosPrenda: () => void;
      updateAndCheckDatosPrenda: (result: DatosPrendaRequest) => void;
      //Titular
      unCheckTitularDomicilio: () => void;
      updateAndCheckTitularDomicilio: (result: DomicilioLegal) => void;
      //Conyuge
      unCheckConyugeDomicilio: () => void;
      updateAndCheckConyugeDomicilio: (result: DomicilioLegal) => void;
      //Codeudor
      unCheckCodeudorDomicilio: () => void;
      updateAndCheckCodeudorDomicilio: (result: DomicilioLegal) => void;
      //ConyugeCodeudor
      unCheckConyugeCodeudorDomicilio: () => void;
      updateAndCheckConyugeCodeudorDomicilio: (result: DomicilioLegal) => void;
      //Vehiculo
      unCheckVehiculo: () => void;
      updateAndCheckVehiculo: (request: VehiculoPrenda) => void;
    };
  };
}

const {
  confeccion: {
    prenda: {
      clear,
      //
      getPrendaInited,
      getPrendaCompleted,
      getPrendaFailed,
      //DatosPrenda
      unCheckDatosPrenda,
      updateAndCheckDatosPrenda,
      //Titular
      unCheckTitularDomicilio,
      updateAndCheckTitularDomicilio,
      //Conyuge
      unCheckConyugeDomicilio,
      updateAndCheckConyugeDomicilio,
      //Codeudor
      unCheckCodeudorDomicilio,
      updateAndCheckCodeudorDomicilio,
      //ConyugeCodeudor
      unCheckConyugeCodeudorDomicilio,
      updateAndCheckConyugeCodeudorDomicilio,
      //Vehiculo, auto
      unCheckVehiculo,
      updateAndCheckVehiculo,
    },
  },
}: IActions = createActions({
  confeccion: {
    prenda: {
      clear: () => ({}),
      //
      getPrendaInited: (props: any) => ({ props }),
      getPrendaCompleted: (response: SolicitudAnalisisResponse) => ({
        ...response,
      }),
      getPrendaFailed: (error: any) => ({ error }),
      //DatosPrenda
      unCheckDatosPrenda: () => ({}),
      updateAndCheckDatosPrenda: (result: DatosPrendaRequest) => ({
        ...result,
      }),
      //Titular
      unCheckTitularDomicilio: () => ({}),
      updateAndCheckTitularDomicilio: (result: DomicilioLegal) => ({
        ...result,
      }),
      //Conyuge
      unCheckConyugeDomicilio: () => ({}),
      updateAndCheckConyugeDomicilio: (result: DomicilioLegal) => ({
        ...result,
      }),
      //Codeudor
      unCheckCodeudorDomicilio: () => ({}),
      updateAndCheckCodeudorDomicilio: (result: DomicilioLegal) => ({
        ...result,
      }),
      //ConyugeCodeudor
      unCheckConyugeCodeudorDomicilio: () => ({}),
      updateAndCheckConyugeCodeudorDomicilio: (result: DomicilioLegal) => ({
        ...result,
      }),
      //Vehiculo
      unCheckVehiculo: () => ({}),
      updateAndCheckVehiculo: (request: VehiculoPrenda) => ({
        ...request,
      }),
    },
  },
});
//Solicitud
const getPrenda = (request: GetPrendaRequest) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { accessToken } = getState().authReducer.session ?? {};
      if (accessToken) {
        dispatch(getPrendaInited(request));
        const response = await confeccionPrenda(accessToken).getPrenda(request);
        dispatch(getPrendaCompleted(response));
      }
    } catch (error) {
      console.error(error);
      dispatch(getPrendaFailed(error));
    }
  };
};

interface IState {
  error: any | null;
  isLoading: boolean;
  prenda: Prenda | undefined;
  cardsChecked: string[];
}

const initialState: IState = {
  error: null,
  isLoading: false,
  prenda: undefined,
  cardsChecked: [],
};

interface IAction<T> {
  payload: T;
}

const confeccionPrendaReducer: Reducer<IState> = handleActions(
  {
    //#region Solicitud
    [clear as any]: () => {
      return {
        ...initialState,
      };
    },
    [getPrendaInited as any]: () => {
      return { ...initialState, isLoading: true };
    },
    [getPrendaCompleted as any]: (state: IState, action: IAction<Prenda>) => {
      let prenda = action.payload;
      prenda.primerVencimiento = dateToString(
        action.payload?.primerVencimiento
      );

      return {
        ...initialState,
        isLoading: false,
        prenda,
      };
    },
    [getPrendaFailed as any]: (state: IState, action: IAction<any>) => {
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    },
    //#endregion
    //#region DatosPrenda
    [unCheckDatosPrenda as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.datosPrenda))
        cardsChecked = cardsChecked.filter((x) => x !== CARD_IDS.datosPrenda);

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateAndCheckDatosPrenda as any]: (
      state: IState,
      action: IAction<DatosPrendaRequest>
    ) => {
      let prenda = JSON.parse(JSON.stringify(state.prenda)) as Prenda;
      const {
        nroFormulario,
        primerVencimiento,
        lugarInscripcion,
        conyugeCotitular,
        codeudorCotitular,
      } = action.payload;
      prenda.nroFormulario = nroFormulario;
      prenda.primerVencimiento = primerVencimiento;
      prenda.lugarInscripcion = lugarInscripcion;
      prenda.conyugeCotitular = conyugeCotitular;
      prenda.codeudorCotitular = codeudorCotitular;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.datosPrenda))
        cardsChecked = [...state.cardsChecked, CARD_IDS.datosPrenda];
      return {
        ...state,
        prenda,
        cardsChecked,
      };
    },
    //#endregion
    //#region Titular
    [unCheckTitularDomicilio as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.titularDomicilio))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.titularDomicilio
        );

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateAndCheckTitularDomicilio as any]: (
      state: IState,
      action: IAction<DomicilioLegal>
    ) => {
      let prenda = JSON.parse(JSON.stringify(state.prenda)) as Prenda;

      prenda.domicilioTitular = action.payload;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.titularDomicilio))
        cardsChecked = [...state.cardsChecked, CARD_IDS.titularDomicilio];
      return {
        ...state,
        prenda,
        cardsChecked,
      };
    },
    //#endregion
    //#region Conyuge
    [unCheckConyugeDomicilio as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.conyugeDomicilio))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.conyugeDomicilio
        );

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateAndCheckConyugeDomicilio as any]: (
      state: IState,
      action: IAction<DomicilioLegal>
    ) => {
      let prenda = JSON.parse(JSON.stringify(state.prenda)) as Prenda;

      prenda.domicilioConyuge = action.payload;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.conyugeDomicilio))
        cardsChecked = [...state.cardsChecked, CARD_IDS.conyugeDomicilio];
      return {
        ...state,
        prenda,
        cardsChecked,
      };
    },
    //#endregion
    //#region Codeudor
    [unCheckCodeudorDomicilio as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.codeudorDomicilio))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.codeudorDomicilio
        );

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateAndCheckCodeudorDomicilio as any]: (
      state: IState,
      action: IAction<DomicilioLegal>
    ) => {
      let prenda = JSON.parse(JSON.stringify(state.prenda)) as Prenda;

      prenda.domicilioCodeudor = action.payload;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.codeudorDomicilio))
        cardsChecked = [...state.cardsChecked, CARD_IDS.codeudorDomicilio];
      return {
        ...state,
        prenda,
        cardsChecked,
      };
    },
    //#endregion
    //#region ConyugeCodeudor
    [unCheckConyugeCodeudorDomicilio as any]: (
      state: IState,
      action: IAction<any>
    ) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.conyugeCodeudorDomicilio))
        cardsChecked = cardsChecked.filter(
          (x) => x !== CARD_IDS.conyugeCodeudorDomicilio
        );

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateAndCheckConyugeCodeudorDomicilio as any]: (
      state: IState,
      action: IAction<DomicilioLegal>
    ) => {
      let prenda = JSON.parse(JSON.stringify(state.prenda)) as Prenda;

      prenda.domicilioConyugeCodeudor = action.payload;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.conyugeCodeudorDomicilio))
        cardsChecked = [
          ...state.cardsChecked,
          CARD_IDS.conyugeCodeudorDomicilio,
        ];
      return {
        ...state,
        prenda,
        cardsChecked,
      };
    },
    //#endregion
    //#region Vehiculo
    [unCheckVehiculo as any]: (state: IState, action: IAction<any>) => {
      let cardsChecked = state.cardsChecked;
      if (cardsChecked.includes(CARD_IDS.vehiculo))
        cardsChecked = cardsChecked.filter((x) => x !== CARD_IDS.vehiculo);

      return {
        ...state,
        cardsChecked,
        isLoading: false,
      };
    },
    [updateAndCheckVehiculo as any]: (
      state: IState,
      action: IAction<VehiculoPrenda>
    ) => {
      let prenda = JSON.parse(JSON.stringify(state.prenda)) as Prenda;
      //Guardar datos
      const { dominio, marcaMotor, nroMotor, marcaChasis, nroChasis, color } =
        action.payload;
      prenda.vehiculo.dominio = dominio;
      prenda.vehiculo.marcaMotor = marcaMotor;
      prenda.vehiculo.nroMotor = nroMotor;
      prenda.vehiculo.marcaChasis = marcaChasis;
      prenda.vehiculo.nroChasis = nroChasis;
      prenda.vehiculo.color = color;

      let cardsChecked = state.cardsChecked;
      if (!cardsChecked.includes(CARD_IDS.vehiculo))
        cardsChecked = [...state.cardsChecked, CARD_IDS.vehiculo];
      return {
        ...state,
        prenda,
        cardsChecked,
      };
    },
    //#endregion
  },
  initialState
);

export default confeccionPrendaReducer;

export {
  clear,
  getPrenda,
  unCheckDatosPrenda,
  updateAndCheckDatosPrenda,
  unCheckTitularDomicilio,
  updateAndCheckTitularDomicilio,
  unCheckConyugeDomicilio,
  updateAndCheckConyugeDomicilio,
  unCheckCodeudorDomicilio,
  updateAndCheckCodeudorDomicilio,
  unCheckConyugeCodeudorDomicilio,
  updateAndCheckConyugeCodeudorDomicilio,
  unCheckVehiculo,
  updateAndCheckVehiculo,
};
