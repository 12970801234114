import React, { ReactNode } from "react";
import pxToRem from "libs/pxToRem";

const ContentSectionMaxWidth: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <section
      style={{
        width:"100%",
        maxWidth: pxToRem(500),
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </section>
  );
};

export default ContentSectionMaxWidth;
