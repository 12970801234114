import styled from "@material-ui/core/styles/styled";
import { ToastContainer } from "react-toastify";

const ToastProvider = styled(ToastContainer)({
  width: "374px",
  "& .Toastify__close-button": { alignSelf: "center", marginLeft: "12px" },
  "& .Toastify__toast": {
    padding: "1rem",
    borderRadius: "8px",
  },
  "& .Toastify__toast-body": {
    padding: 0,
  },
});

export default ToastProvider;
